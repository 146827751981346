import api from '../../utils/api';
import auth from '../../utils/auth';

const saveUserData = (jwt, user) => {
  auth.setToken(jwt);
  localStorage.setItem('user', JSON.stringify(user));
};

const login = async (usernameOrEmail, password) => {
  try {
    const response = await api.post('/auth/login', { usernameOrEmail, password });
    if (response.data.jwt) {
      saveUserData(response.data.jwt, response.data.user);
      return response.data;
    } else {
      throw new Error('JWT token not found in response');
    }
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Login failed. Please try again.');
  }
};

const logout = () => {
  auth.removeToken();
  localStorage.removeItem('user');
  window.location.href = '/login';
};

const AuthService = {
  login,
  logout,
  getCurrentUser: () => {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
  },
  register: async (userData) => {
    try {
      const response = await api.post('/users/register', userData);
      if (response.data.jwt) {
        saveUserData(response.data.jwt, response.data.user);
        return response.data;
      } else {
        throw new Error('JWT token not found in response');
      }
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Registration failed. Please try again.');
    }
  },
};

export default AuthService;
