// src/components/common/Sections.js

import styled from 'styled-components';

// Section Wrapper
export const Section = styled.section`
  padding: 5rem 0; /* 80px */
  background-color: ${(props) => props.theme.colors.darkBackground};
  text-align: center;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 3rem 0; /* 48px */
  }
`;

// Icon Wrapper for Mission and Vision
export const IconWrapper = styled.div`
  font-size: 3rem;
  color: ${(props) => props.theme.colors.secondary};
  margin-bottom: 1.25rem; /* 20px */
  transition: color 0.3s ease;

  &:hover,
  &:focus {
    color: ${(props) => props.theme.colors.accents[0]};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 2.5rem;
  }
`;

// Horizontal Line
export const HorizontalLine = styled.hr`
  width: 80px;
  height: 4px;
  background-color: ${(props) => props.theme.colors.secondary};
  margin: 20px auto;
  border: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.accents[0]};
  }
`;

// Mission and Vision Icons
export const MissionIcon = styled.div`
  font-size: 3rem;
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: 20px;
  transition: color 0.3s ease;

  &:hover {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const VisionIcon = styled(MissionIcon)``;

// Mission and Vision Titles
export const MissionTitle = styled.h2`
  font-size: ${(props) => props.theme.fontSizes['2xl']};
  margin-bottom: 20px;
  color: ${(props) => props.theme.colors.textPrimary};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 1.75rem;
  }
`;

export const VisionTitle = styled(MissionTitle)``;

// Generic Section Title for Mission and Vision
export const SectionTitle = styled.h2`
  font-size: ${(props) => props.theme.fontSizes['2xl']};
  margin-bottom: 1.25rem; /* 20px */
  color: ${(props) => props.theme.colors.secondary};
  font-family: ${(props) => props.theme.fonts.primary};
  transition: color 0.3s ease;

  &:hover {
    color: ${(props) => props.theme.colors.accents[0]};
  }


  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 1.75rem; /* 28px */
  }
`;

export const ResponsiveIframe = styled.div`
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  position: relative;
  border-radius: 0.75rem; /* 12px */
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.2); /* 4px 12px 12px */
  border: 2px solid ${(props) => props.theme.colors.border};

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0.75rem; /* 12px */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  &:hover iframe,
  &:focus iframe {
    transform: scale(1.02);
    box-shadow: 0 0.5rem 1.25rem rgba(0, 0, 0, 0.3); /* 8px 20px 20px */
  }
`;

export const ContentWrapper = styled.div`
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  box-shadow: ${({ theme }) => theme.shadows.medium};
  text-align: left;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    text-align: center;
    padding: 1.5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 1rem;
  }
`;