// src/components/admin/CreateDiscountCode.jsx

import React, { useState } from 'react';
import api from '../../utils/api';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, MenuItem, 
  Typography, Snackbar,
   Alert, InputLabel, FormControl, Select } from '@mui/material';
import styled from 'styled-components';
import { COLORS } from '../../BrandColors';

const CreateDiscountCode = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    code: '',
    description: '',
    discountType: 'PERCENTAGE', // or 'FIXED_AMOUNT'
    value: '',
    expirationDate: '',
    usageLimit: '',
    isInfluencerCode: false,
  });

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await api.post('/admin/discount-codes/create', formData);
      console.log("Discount code Response: ", response.data);
      
      setSuccessMessage('Discount code created successfully.');
      navigate('/admin/dashboard');
    } catch (error) {
      console.error('Error creating discount code:', error);
      setErrorMessage('Failed to create discount code. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Title variant="h4">Create Discount Code</Title>
      <Form onSubmit={handleSubmit}>
        <StyledTextField
          label="Code"
          name="code"
          value={formData.code}
          onChange={handleChange}
          required
          variant="outlined"
        />
        <StyledTextField
          label="Description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          variant="outlined"
        />
        <FormControlStyled variant="outlined" required>
          <InputLabel id="discount-type-label">Discount Type</InputLabel>
          <StyledSelect
            labelId="discount-type-label"
            name="discountType"
            value={formData.discountType}
            onChange={handleChange}
            label="Discount Type"
          >
            <MenuItem value="PERCENTAGE">Percentage</MenuItem>
            <MenuItem value="FIXED_AMOUNT">Fixed Amount</MenuItem>
          </StyledSelect>
        </FormControlStyled>
        <StyledTextField
          label="Value"
          name="value"
          value={formData.value}
          onChange={handleChange}
          required
          type="number"
          variant="outlined"
        />
        <StyledTextField
          label="Expiration Date"
          name="expirationDate"
          value={formData.expirationDate}
          onChange={handleChange}
          required
          type="date"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
        />
        <StyledTextField
          label="Usage Limit"
          name="usageLimit"
          value={formData.usageLimit}
          onChange={handleChange}
          type="number"
          variant="outlined"
        />
        <StyledIsInfluencerCheckbox>
          <Typography>Influencer Code</Typography>
          <input type="checkbox" name="isInfluencerCode" onChange={handleChange}/>
        </StyledIsInfluencerCheckbox>
        <StyledButton type="submit" variant="contained" color="primary" disabled={loading}>
          {loading ? 'Creating...' : 'Create'}
        </StyledButton>
      </Form>

      {/* Success Snackbar */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Error Snackbar */}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setErrorMessage('')} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CreateDiscountCode;

// Styled Components
const Container = styled.div`
  background-color: ${COLORS.primaryBackground};
  padding: 40px;
  max-width: 600px;
  margin: 40px auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled(Typography)`
  color: ${COLORS.accentBlueGray};
  margin-bottom: 20px !important;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    background-color: #fff;
  }
`;

const FormControlStyled = styled(FormControl)`
  & .MuiInputLabel-outlined {
    color: ${COLORS.accentBlueGray};
  }
  & .MuiOutlinedInput-root {
    background-color: #fff;
  }
`;

const StyledSelect = styled(Select)`
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${COLORS.accentBlueGray};
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${COLORS.accentBlueGray};
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${COLORS.accentBlueGray};
  }
`;

const StyledButton = styled(Button)`
  background-color: ${COLORS.secondaryButton};
  color: #fff;
  &:hover {
    background-color: ${COLORS.accentOrange};
  }
`;

const StyledIsInfluencerCheckbox = styled(FormControl)`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
`;
