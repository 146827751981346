import React from 'react';
import VideoDetails from '../components/Video/VideoDetails';
import NavBar from '../components/common/NavBar';
import Footer from '../components/common/Footer';
import styled from 'styled-components';

const VideoPageContainer = styled.div`
  background-color: #f0f2f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const MainContent = styled.main`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const VideoDetailsPage = () => {
  return (
    <VideoPageContainer>
      <NavBar />
      <MainContent>
        <VideoDetails />
      </MainContent>
      <Footer />
    </VideoPageContainer>
  );
};

export default VideoDetailsPage;
