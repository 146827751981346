import React, { useEffect, useState } from 'react';
import api from '../../utils/api';
import { Link } from 'react-router-dom';
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Snackbar,
  Alert,
} from '@mui/material';
import styled from 'styled-components';
import {media} from "../../utils/media";

const AdminDashboard = () => {
  const [discountCodes, setDiscountCodes] = useState([]);
  const [users, setUsers] = useState([]);
  const [feedbackList, setFeedbackList] = useState([]); // Feedback state

  // States for user role assignment
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [loadingAssign, setLoadingAssign] = useState(false);
  const [assignSuccess, setAssignSuccess] = useState('');
  const [assignError, setAssignError] = useState('');

  // States for deletion success/error
  const [deleteSuccess, setDeleteSuccess] = useState('');
  const [deleteError, setDeleteError] = useState('');

  // States for feedback-related success/error
  const [feedbackError, setFeedbackError] = useState('');
  const [deleteFeedbackSuccess, setDeleteFeedbackSuccess] = useState('');

  // Fetch discount codes
  useEffect(() => {
    const fetchDiscountCodes = async () => {
      try {
        const response = await api.get('/admin/discount-codes/all');
        setDiscountCodes(response.data);
        console.log("All discount codes: ", response.data)
      } catch (error) {
        console.error('Error fetching discount codes:', error);
        setDeleteError('Failed to fetch discount codes.');
      }
    };
    fetchDiscountCodes();
  }, []);

  // Fetch users
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get('/users/admin/users');
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
        setAssignError('Failed to fetch users.');
      }
    };
    fetchUsers();
  }, []);

  // Fetch feedback
  useEffect(() => {
    const fetchFeedback = async () => {
      try {
        const response = await api.get('/feedback/all'); // Corrected feedback route
        setFeedbackList(response.data);
      } catch (error) {
        console.error('Error fetching feedback:', error);
        setFeedbackError('Failed to fetch feedback.');
      }
    };
    fetchFeedback();
  }, []);

  // Handle deletion of discount codes
  const handleDelete = async (id) => {
    try {
      await api.delete(`/admin/discount-codes/${id}`);
      setDiscountCodes((prevCodes) => prevCodes.filter((code) => code.id !== id));
      setDeleteSuccess('Discount code deleted successfully.');
    } catch (error) {
      console.error('Error deleting discount code:', error);
      setDeleteError('Failed to delete discount code.');
    }
  };

  // Handle feedback deletion
  const handleDeleteFeedback = async (feedbackId) => {
    try {
      await api.delete(`/feedback/delete/${feedbackId}`); // Corrected feedback delete route
      setFeedbackList((prevFeedback) => prevFeedback.filter((fb) => fb.id !== feedbackId));
      setDeleteFeedbackSuccess('Feedback deleted successfully.');
    } catch (error) {
      console.error('Error deleting feedback:', error);
      setFeedbackError('Failed to delete feedback.');
    }
  };

  // Handle role assignment
  const handleAssignRole = async (e) => {
    e.preventDefault();

    if (!selectedUser || !selectedRole) {
      setAssignError('Please select both a user and a role.');
      return;
    }

    try {
      setLoadingAssign(true);
      await api.post('/users/admin/add-role', {
        userName: selectedUser,
        roleName: selectedRole.toUpperCase(),
      });
      setAssignSuccess(`Role "${selectedRole}" assigned to "${selectedUser}" successfully.`);
      const response = await api.get('/users/admin/users');
      setUsers(response.data);
      setSelectedUser('');
      setSelectedRole('');
    } catch (error) {
      console.error('Error assigning role:', error);
      setAssignError(error.response?.data?.message || 'Failed to assign role.');
    } finally {
      setLoadingAssign(false);
    }
  };

  return (
      <Container>
        <Title variant="h3" gutterBottom>
          Admin Dashboard
        </Title>

        {/* Discount Codes Section */}
        <Section>
          <SectionHeader>
            <Typography variant="h5">Discount Codes</Typography>
            <ResponsiveButton
                component={Link}
                to="/admin/discount-code/create"
                variant="contained"
                color="secondary"
            >
              Create New Discount Code
            </ResponsiveButton>
          </SectionHeader>
          <TableWrapper>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Code</StyledTableCell>
                  <StyledTableCell>Description</StyledTableCell>
                  <StyledTableCell>Type</StyledTableCell>
                  <StyledTableCell>Value</StyledTableCell>
                  <StyledTableCell>Expiration Date</StyledTableCell>
                  <StyledTableCell>Usage Limit</StyledTableCell>
                  <StyledTableCell>Times Used</StyledTableCell>
                  <StyledTableCell>Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {discountCodes.map((code) => (
                    <TableRow key={code.id}>
                      <TableCell>{code.code}</TableCell>
                      <TableCell>{code.description}</TableCell>
                      <TableCell>{code.discountType}</TableCell>
                      <TableCell>{code.value}</TableCell>
                      <TableCell>{new Date(code.expirationDate).toLocaleDateString()}</TableCell>
                      <TableCell>{code.usageLimit}</TableCell>
                      <TableCell>{code.timesUsed}</TableCell>
                      <TableCell>
                        <StyledLinkButton
                            component={Link}
                            to={`/admin/discount-code/update/${code.id}`}
                            variant="outlined"
                            color="secondary"
                        >
                          Edit
                        </StyledLinkButton>
                        <StyledDeleteButton
                            variant="outlined"
                            color="error"
                            onClick={() => handleDelete(code.id)}
                        >
                          Delete
                        </StyledDeleteButton>
                      </TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </TableWrapper>
        </Section>

        {/* User Roles Section */}
        <Section>
          <SectionHeader>
            <Typography variant="h5">User Roles</Typography>
          </SectionHeader>
          <Form onSubmit={handleAssignRole}>
            <FormControlStyled variant="outlined" required>
              <InputLabel id="select-user-label">Select User</InputLabel>
              <StyledSelect
                  labelId="select-user-label"
                  value={selectedUser}
                  label="Select User"
                  onChange={(e) => setSelectedUser(e.target.value)}
              >
                {users.map((user) => (
                    <MenuItem key={user.id} value={user.username}>
                      {user.username} ({user.email}) - Current Role: {user.roles[0]}
                    </MenuItem>
                ))}
              </StyledSelect>
            </FormControlStyled>

            <FormControlStyled variant="outlined" required>
              <InputLabel id="select-role-label">Select Role</InputLabel>
              <StyledSelect
                  labelId="select-role-label"
                  value={selectedRole}
                  label="Select Role"
                  onChange={(e) => setSelectedRole(e.target.value)}
              >
                <MenuItem value="ADMIN">Admin</MenuItem>
                <MenuItem value="USER">User</MenuItem>
              </StyledSelect>
            </FormControlStyled>

            <StyledSubmitButton type="submit" variant="contained" color="secondary" disabled={loadingAssign}>
              {loadingAssign ? 'Assigning...' : 'Assign Role'}
            </StyledSubmitButton>
          </Form>
        </Section>

        {/* Feedback Management Section */}
        <Section>
          <SectionHeader>
            <Typography variant="h5">Feedback Management</Typography>
          </SectionHeader>
          <TableWrapper>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Username</StyledTableCell>
                  <StyledTableCell>Email</StyledTableCell>
                  <StyledTableCell>Content</StyledTableCell>
                  <StyledTableCell>Rating</StyledTableCell>
                  <StyledTableCell>Timestamp</StyledTableCell>
                  <StyledTableCell>Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {feedbackList.map((feedback) => (
                    <TableRow key={feedback.id}>
                      <TableCell>{feedback.username}</TableCell>
                      <TableCell>{feedback.email}</TableCell>
                      <TableCell>{feedback.feedbackContent}</TableCell>
                      <TableCell>{feedback.rating || 'N/A'}</TableCell>
                      <TableCell>{new Date(feedback.timestamp).toLocaleString()}</TableCell>
                      <TableCell>
                        <StyledDeleteButton
                            variant="outlined"
                            color="error"
                            onClick={() => handleDeleteFeedback(feedback.id)}
                        >
                          Delete
                        </StyledDeleteButton>
                      </TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </TableWrapper>
        </Section>

        {/* Success and Error Notifications */}
        <Snackbar open={!!deleteSuccess} autoHideDuration={6000} onClose={() => setDeleteSuccess('')}>
          <Alert onClose={() => setDeleteSuccess('')} severity="success" sx={{ width: '100%' }}>
            {deleteSuccess}
          </Alert>
        </Snackbar>

        <Snackbar open={!!deleteError} autoHideDuration={6000} onClose={() => setDeleteError('')}>
          <Alert onClose={() => setDeleteError('')} severity="error" sx={{ width: '100%' }}>
            {deleteError}
          </Alert>
        </Snackbar>

        <Snackbar open={!!assignSuccess} autoHideDuration={6000} onClose={() => setAssignSuccess('')}>
          <Alert onClose={() => setAssignSuccess('')} severity="success" sx={{ width: '100%' }}>
            {assignSuccess}
          </Alert>
        </Snackbar>

        <Snackbar open={!!assignError} autoHideDuration={6000} onClose={() => setAssignError('')}>
          <Alert onClose={() => setAssignError('')} severity="error" sx={{ width: '100%' }}>
            {assignError}
          </Alert>
        </Snackbar>

        {/* Feedback Success and Error Notifications */}
        <Snackbar open={!!deleteFeedbackSuccess} autoHideDuration={6000} onClose={() => setDeleteFeedbackSuccess('')}>
          <Alert onClose={() => setDeleteFeedbackSuccess('')} severity="success" sx={{ width: '100%' }}>
            {deleteFeedbackSuccess}
          </Alert>
        </Snackbar>

        <Snackbar open={!!feedbackError} autoHideDuration={6000} onClose={() => setFeedbackError('')}>
          <Alert onClose={() => setFeedbackError('')} severity="error" sx={{ width: '100%' }}>
            {feedbackError}
          </Alert>
        </Snackbar>
      </Container>
  );
};

export default AdminDashboard;


// Styled Components
const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  padding: 40px;
  max-width: 1200px;
  margin: 40px auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  ${media.mobile`
    padding: 20px;
    margin: 20px auto;
  `}
`;

const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.accentBlueGray};
  margin-bottom: 20px !important;
  text-align: center;
`;

const Section = styled.div`
  margin-bottom: 60px;

  ${media.mobile`
    margin-bottom: 30px;
  `}
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  ${media.mobile`
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  `}
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
  margin-bottom: 20px;
`;

const StyledTable = styled(Table)`
  min-width: 700px;
  & .MuiTableCell-root {
    border-bottom: 1px solid ${({ theme }) => theme.colors.accentBlueGray};
  }
`;

const StyledTableCell = styled(TableCell)`
  color: ${({ theme }) => theme.colors.accentBlueGray};
  font-weight: bold;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 600px;
  margin: 0 auto;

  ${media.mobile`
    gap: 15px;
  `}
`;

const FormControlStyled = styled(FormControl)`
  & .MuiInputLabel-outlined {
    color: ${({ theme }) => theme.colors.accentBlueGray};
  }
  & .MuiOutlinedInput-root {
    background-color: #fff;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.accentBlueGray};
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.accentBlueGray};
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.accentBlueGray};
  }
`;

const StyledSelect = styled(Select)`
  & .MuiSelect-select {
    color: ${({ theme }) => theme.colors.accentBlueGray};
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.accentBlueGray};
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.accentBlueGray};
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.accentBlueGray};
  }
`;

const StyledLinkButton = styled(Button)`
  margin-right: 8px;
  border-color: ${({ theme }) => theme.colors.accentTeal};
  color: ${({ theme }) => theme.colors.accentTeal};
  &:hover {
    background-color: ${({ theme }) => theme.colors.accentTeal};
    color: #fff;
  }

  ${media.mobile`
    margin-right: 0;
    width: 100%;
  `}
`;

const StyledDeleteButton = styled(Button)`
  border-color: ${({ theme }) => theme.colors.accentOrange};
  color: ${({ theme }) => theme.colors.accentOrange};
  &:hover {
    background-color: ${({ theme }) => theme.colors.accentOrange};
    color: #fff;
  }

  ${media.mobile`
    width: 100%;
    margin-top: 8px;
  `}
`;

const ResponsiveButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.secondaryButton};
  color: #fff;
  &:hover {
    background-color: ${({ theme }) => theme.colors.accentOrange};
  }

  ${media.mobile`
    padding: 8px 16px;
    font-size: 0.9rem;
    width: 100%;
  `}
`;

const StyledSubmitButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.secondaryButton};
  color: #fff;
  &:hover {
    background-color: ${({ theme }) => theme.colors.accentOrange};
  }

  ${media.mobile`
    padding: 10px 20px;
    font-size: 0.9rem;
  `}
`;
