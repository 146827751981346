// src/pages/Landing.js

import React, { useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { theme } from '../components/common/theme';

import NavBar from '../components/common/NavBar';
import HeroSection from '../components/HeroSection';
import VisionSection from '../components/VisionSection';
import MissionSection from '../components/MissionSection';
import CompetitiveAdvantageSection from '../components/CompetitiveAdvantageSection';
import Footer from '../components/common/Footer';
import CompetitiveAdvantage from '../components/CompetitiveAdvantage';
import { SectionTitle } from '../components/common/Sections';
import { useNavigate } from 'react-router-dom';

const Landing = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);


  const handleLogin = () => {
    // Handle login logic
    navigate('/login');
  };

  const logout = () => {
    // Handle logout logic
  };

  return (
    <ThemeProvider theme={theme}>
      <LandingPageContainer>
        <NavBar user={user} logout={logout} />

        <HeroSection user={user} handleLogin={handleLogin} />

        <VisionSection />

        <MissionSection />

        <CompetitiveAdvantageSection />

        <CompetitiveAdvantage />

        <Footer />
      </LandingPageContainer>
    </ThemeProvider>
  );
};

export default Landing;

// Styled Components

const LandingPageContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  overflow-x: hidden;
  scroll-behavior: smooth;
`;
