// src/components/VideoTrimmer.js

import React, { useState, useRef } from 'react';
import api from '../../utils/api';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Form, ProgressBar } from 'react-bootstrap';
import { toast } from 'react-toastify';

const formatVideoDuration = (duration) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor(duration % 60);

  const hoursDisplay = hours > 0 ? `${hours}:` : '';
  const minutesDisplay = `${hours > 0 ? minutes.toString().padStart(2, '0') : minutes}:`;
  const secondsDisplay = seconds.toString().padStart(2, '0');

  return `${hoursDisplay}${minutesDisplay}${secondsDisplay}`;
};

const VideoTrimmer = ({ video, onClose }) => {
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(video.duration || 0);
  const [loading, setLoading] = useState(false);
  const [trackingId, setTrackingId] = useState(null);
  const [progress, setProgress] = useState(0);
  const [trimmedVideoUrl, setTrimmedVideoUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [downloadProgress, setDownloadProgress] = useState(0);
  const videoRef = useRef(null);
  // Time inputs in minutes and seconds
const [startMinutes, setStartMinutes] = useState(0);
const [startSeconds, setStartSeconds] = useState(0);
const [endMinutes, setEndMinutes] = useState(0);
const [endSeconds, setEndSeconds] = useState(0);

const handleTrim = async () => {
  setLoading(true);
  setErrorMessage('');
  setProgress(0);
  setTrackingId(null);

  const startTotalSeconds = startMinutes * 60 + startSeconds;
  const endTotalSeconds = endMinutes * 60 + endSeconds;

  // Validate times
  if (startTotalSeconds >= endTotalSeconds) {
    setErrorMessage('Start time must be less than end time.');
    setLoading(false);
    return;
  }

  if (endTotalSeconds > videoRef.current.duration) {
    setErrorMessage('End time exceeds video duration.');
    setLoading(false);
    return;
  }

  try {
    const response = await api.post(`/videos/${video.id}/trim`, null, {
      params: {
        startSeconds: startTotalSeconds,
        endSeconds: endTotalSeconds,
      },
    });

    const trackingId = response.data;
    setTrackingId(trackingId);

    pollTrimStatus(trackingId);
  } catch (error) {
    console.error('An error occurred while initiating trimming:', error);
    setErrorMessage('Failed to initiate trimming. Please try again.');
    setLoading(false);
  }
};

  const pollTrimStatus = (trackingId) => {
    const pollInterval = 5000;

    const poll = async () => {
      try {
        const response = await api.get('/videos/trim/status', {
          params: { trackingId },
        });

        const statusInfo = response.data;
        setProgress(statusInfo.videoProcessingProgress);

        if (statusInfo.videoProcessingStatus === 'PROCESSED') {
          setLoading(false);
          const preSignedUrl = statusInfo.preSignedUrl;

          if (preSignedUrl) {
            setTrimmedVideoUrl(preSignedUrl);
            toast.success('Video trimming completed.');
          } else {
            setErrorMessage('Failed to retrieve the trimmed video URL.');
          }
        } else if (statusInfo.videoProcessingStatus === 'FAILED') {
          setLoading(false);
          setErrorMessage(statusInfo.videoProcessingErrorMessage || 'Trimming failed.');
          toast.error('Video trimming failed.');
        } else {
          setTimeout(poll, pollInterval);
        }
      } catch (error) {
        console.error('Error while polling trimming status:', error);
        setErrorMessage('Error while checking trimming status.');
        setLoading(false);
      }
    };

    poll();
  };

  const handleDownloadTrimmedVideo = async () => {
    if (!trimmedVideoUrl) {
      setErrorMessage('Trimmed video URL is not available.');
      return;
    }
    setLoading(true);
    setErrorMessage('');
    setDownloadProgress(0);
  
    try {
      const response = await fetch(trimmedVideoUrl);
      if (!response.ok) {
        throw new Error('Failed to download the trimmed video.');
      }

      const contentLength = response.headers.get('Content-Length');
      if (!contentLength) {
        throw new Error('Content-Length header is missing.');
      }

      const total = parseInt(contentLength, 10);
      let loaded = 0;
      const reader = response.body.getReader();
      const chunks = [];

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        chunks.push(value);
        loaded += value.length;
        const progress = Math.floor((loaded / total) * 100);
        setDownloadProgress(progress);
      }

      const blob = new Blob(chunks, { type: 'video/mp4' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${video.title}.mp4`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

      setLoading(false);
      toast.success('Trimmed video downloaded successfully.');
    } catch (error) {
      console.error('Error downloading the trimmed video:', error);
      setErrorMessage('Failed to download the trimmed video. Please try again.');
      setLoading(false);
    }
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: `${video.title} - Trimmed Video`,
          text: 'Check out this video I trimmed!',
          url: trimmedVideoUrl,
        });
      } else {
        toast.info('Your browser does not support sharing.');
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  const encodeParams = (params) => {
    return Object.keys(params)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join('&');
  };

  const twitterShareUrl = `https://twitter.com/intent/tweet?${encodeParams({
    text: 'Check out this video I trimmed!',
    url: trimmedVideoUrl,
  })}`;

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    trimmedVideoUrl
  )}`;

  return (
    <TrimmerContainer>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {!trimmedVideoUrl && (
        <>
          <VideoPlayer
            ref={videoRef}
            src={video.preSignedS3Url}
            controls
            onLoadedMetadata={() => {
              const totalDuration = videoRef.current.duration;
              setEndMinutes(Math.floor(totalDuration / 60));
              setEndSeconds(Math.floor(totalDuration % 60));
            }}
          />
          <Form>
  <TimeInputGroup controlId="startTime">
    <Form.Label>Start Time (Minutes):</Form.Label>
    <TimeInputRow>
      <TimeInput
        type="number"
        value={startMinutes}
        min="0"
        onChange={(e) => setStartMinutes(Number(e.target.value))}
        placeholder="Min"
      />
      <Colon>:</Colon>
      <TimeInput
        type="number"
        value={startSeconds}
        min="0"
        max="59"
        onChange={(e) => setStartSeconds(Number(e.target.value))}
        placeholder="Sec"
      />
    </TimeInputRow>
  </TimeInputGroup>

  <TimeInputGroup controlId="endTime">
    <Form.Label>End Time (Minutes):</Form.Label>
    <TimeInputRow>
      <TimeInput
        type="number"
        value={endMinutes}
        min="0"
        onChange={(e) => setEndMinutes(Number(e.target.value))}
        placeholder="Min"
      />
      <Colon>:</Colon>
      <TimeInput
        type="number"
        value={endSeconds}
        min="0"
        max="59"
        onChange={(e) => setEndSeconds(Number(e.target.value))}
        placeholder="Sec"
      />
    </TimeInputRow>
  </TimeInputGroup>

  <TrimButton onClick={handleTrim} disabled={loading}>
    {loading ? 'Trimming...' : 'Trim Video'}
  </TrimButton>
</Form>
          {loading && <ProgressBar now={progress} label={`${progress}%`} />}
        </>
      )}
      {trimmedVideoUrl && (
        <div>
          <p>Video trimming is complete.</p>
          <VideoPlayer src={trimmedVideoUrl} controls />

          <ButtonGroup>
            {loading && (
            <div style={{ marginTop: '1rem' }}>
                <ProgressBar now={downloadProgress} label={`${downloadProgress}%`} />
              </div>)}

              <StyledButton onClick={handleDownloadTrimmedVideo} disabled={loading}>
                {loading ? 'Downloading...' : 'Download'}
              </StyledButton>
            <StyledCloseButton variant="secondary" onClick={onClose}>Close</StyledCloseButton>
          </ButtonGroup>

          <ShareContainer>
            <p>Share your trimmed video:</p>
            <SocialButtons>
              <SocialButton
                href={twitterShareUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Share on Twitter
              </SocialButton>

              <SocialButton
                href={facebookShareUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Share on Facebook
              </SocialButton>

              <SocialButton
                onClick={() =>
                  toast.info(
                    'To share on Instagram, please download the video and share it via the Instagram app.'
                  )
                }
              >
                Share on Instagram
              </SocialButton>
            </SocialButtons>
          </ShareContainer>
        </div>
      )}
    </TrimmerContainer>
  );
};

VideoTrimmer.propTypes = {
  video: PropTypes.shape({
    id: PropTypes.number.isRequired,
    preSignedS3Url: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default VideoTrimmer;

// Styled Components with Theme Integration
const TrimmerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md};
  padding: ${({ theme }) => theme.spacing.lg};
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
`;

const ShareContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const SocialButtons = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};
  flex-wrap: wrap;
`;

const TimeInputRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};
`;

const Colon = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.md};
  margin: 0 ${({ theme }) => theme.spacing.xs};
`;

const SocialButton = styled.a`
  display: inline-block;
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
  border-radius: ${({ theme }) => theme.spacing.xs};
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};
  margin-top: ${({ theme }) => theme.spacing.md};
`;

const StyledButton = styled.button`
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  background-color: ${({ theme }) => theme.colors.secondary};
  border: none;
  border-radius: ${({ theme }) => theme.spacing.xs};
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: white;
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.button};

  &:hover {
    background-color: ${({ theme }) => theme.colors.accents[0]};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.disabledBackground};
    cursor: not-allowed;
  }
`;

const StyledCloseButton = styled(StyledButton)`
  background-color: ${({ theme }) => theme.colors.accents[2]};
`;

const VideoPlayer = styled.video`
  width: 100%;
  border-radius: ${({ theme }) => theme.spacing.xs};
`;

const TimeInputGroup = styled(Form.Group)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm};
`;

const TimeInput = styled(Form.Control)`
  padding: ${({ theme }) => theme.spacing.sm};
  border-radius: ${({ theme }) => theme.spacing.xs};
  border: 1px solid ${({ theme }) => theme.colors.border};

  &:focus {
    border-color: ${({ theme }) => theme.colors.secondary};
    box-shadow: none;
  }
`;

const TrimButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing.md};
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  border-radius: ${({ theme }) => theme.spacing.xs};
  font-size: ${({ theme }) => theme.fontSizes.md};

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.disabledBackground};
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-size: ${({ theme }) => theme.fontSizes.sm};
`;
