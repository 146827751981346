// src/utils/media.js

export const media = {
  mobile: (styles) => `
    @media (max-width: 480px) {
      ${styles}
    }
  `,
  tablet: (styles) => `
    @media (min-width: 481px) and (max-width: 768px) {
      ${styles}
    }
  `,
  desktop: (styles) => `
    @media (min-width: 769px) and (max-width: 1024px) {
      ${styles}
    }
  `,
  largeDesktop: (styles) => `
    @media (min-width: 1025px) {
      ${styles}
    }
  `,
};
