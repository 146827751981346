import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ children, requiredRole }) => {
  const user = useSelector((state) => state.auth.user);

  if (!user) {
    // User is not authenticated
    return <Navigate to="/login" />;
  }

  if (requiredRole && user.roles[0] !== requiredRole.toUpperCase()) {
    // User does not have the required role
    return <Navigate to="/unauthorized" />;
  }

  // User is authenticated and has the required role (if any)
  return children;
};

export default PrivateRoute;
