import React, { createContext, useEffect, useContext, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login as loginAction, logout as logoutAction, checkAuth } from '../../store/authSlice';
import auth from '../../utils/auth';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { user, isLoading, error } = useSelector((state) => state.auth);

  // Checking authentication on mount
  useEffect(() => {
    dispatch(checkAuth()); // Trigger an action to check if the user is logged in
  }, [dispatch]);

  // Memoized login function to avoid unnecessary re-renders
  const login = useCallback(
    async (usernameOrEmail, password) => {
      try {
        await dispatch(loginAction({ usernameOrEmail, password }));
      } catch (error) {
        console.error('Login failed', error);
        throw error;
      }
    },
    [dispatch]
  );

  // Memoized logout function
  const logout = useCallback(() => {
    dispatch(logoutAction());
  }, [dispatch]);

  return (
    <AuthContext.Provider value={{ user, isLoading, error, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom Hook for Consuming AuthContext
export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
