import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, MenuItem, Box, Typography, Snackbar, Alert } from '@mui/material';
import api from '../../utils/api';

const EditDiscountCode = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [formData, setFormData] = useState({
    code: '',
    description: '',
    discountType: 'PERCENTAGE',
    value: '',
    expirationDate: '',
    usageLimit: '',
  });

  const [originalCode, setOriginalCode] = useState(''); // Store the original code
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Fetch existing discount code data
  useEffect(() => {
    const fetchDiscountCode = async () => {
      try {
        const response = await api.get(`/admin/discount-codes/${id}`);
        setFormData(response.data);
        setOriginalCode(response.data.code); // Store the original code
      } catch (error) {
        console.error('Error fetching discount code:', error);
      }
    };
    fetchDiscountCode();
  }, [id]);

  // Handle input changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setErrors({ ...errors, [e.target.name]: '' }); // Clear error for the field being edited
  };

  // Validate form inputs
  const validateForm = () => {
    const newErrors = {};
    if (!formData.code.trim()) {
      newErrors.code = 'Code is required.';
    } else if (formData.code === originalCode) {
      newErrors.code = 'Code must be changed to a new value.';
    }

    if (!formData.value) {
      newErrors.value = 'Value is required.';
    }
    if (!formData.expirationDate) {
      newErrors.expirationDate = 'Expiration Date is required.';
    }
    return newErrors;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      await api.put(`/admin/discount-codes/${id}`, formData);
      setSuccessMessage('Discount code updated successfully!');
      setOpenSnackbar(true);
      setTimeout(() => {
        navigate('/admin/dashboard'); // Redirect to dashboard after success
      }, 3000); // Redirect after 3 seconds
    } catch (error) {
      console.error('Error updating discount code:', error);
    }
  };

  // Handle Snackbar close
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box
      sx={{
        maxWidth: 600,
        margin: 'auto',
        padding: 3,
        borderRadius: 2,
        boxShadow: 3,
        backgroundColor: '#f9f9f9',
      }}
    >
      <Typography variant="h4" component="h1" sx={{ marginBottom: 3, textAlign: 'center' }}>
        Edit Discount Code
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Code"
          name="code"
          value={formData.code}
          onChange={handleChange}
          fullWidth
          margin="normal"
          error={Boolean(errors.code)}
          helperText={errors.code}
          required
        />
        <TextField
          label="Description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          select
          label="Discount Type"
          name="discountType"
          value={formData.discountType}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        >
          <MenuItem value="PERCENTAGE">Percentage</MenuItem>
          <MenuItem value="FIXED_AMOUNT">Fixed Amount</MenuItem>
        </TextField>
        <TextField
          label="Value"
          name="value"
          value={formData.value}
          onChange={handleChange}
          type="number"
          fullWidth
          margin="normal"
          error={Boolean(errors.value)}
          helperText={errors.value}
          required
        />
        <TextField
          label="Expiration Date"
          name="expirationDate"
          value={formData.expirationDate}
          onChange={handleChange}
          type="date"
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          error={Boolean(errors.expirationDate)}
          helperText={errors.expirationDate}
          required
        />
        <TextField
          label="Usage Limit"
          name="usageLimit"
          value={formData.usageLimit}
          onChange={handleChange}
          type="number"
          fullWidth
          margin="normal"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ marginTop: 2 }}
          fullWidth
        >
          Update
        </Button>
      </form>

      {/* Snackbar for success message */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EditDiscountCode;
