import styled from 'styled-components';
import { media } from '../../../utils/media';
import { FaSpinner } from 'react-icons/fa';
import { Modal, Form, Button } from 'react-bootstrap';

// Styled Components

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 320px;
  margin: 15px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  }

  ${media.tablet(`
    max-width: 280px;
    margin: 10px;
  `)}

  ${media.mobile(`
    max-width: 100%;
    margin: 10px 0;
  `)}
`;

export const ButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
`;


export const CheckboxContainer = styled.div`
  margin: 10px;

  ${media.mobile(`
    margin: 8px;
  `)}
`;


export const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const TooltipText = styled.span`
  visibility: hidden;
  width: 180px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the checkbox */
  left: 50%;
  transform: translateX(-50%); /* Center the tooltip */

  /* Tooltip arrow */
  &::after {
    content: '';
    position: absolute;
    top: 100%; /* At the bottom of tooltip */
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  /* Show the tooltip on hover */
  ${TooltipWrapper}:hover & {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s;
  }
`;

export const ThumbnailContainer = styled.div`
  position: relative;
  border-radius: 12px 12px 0 0;
  overflow: hidden;

  ${media.mobile(`
    border-radius: 12px;
  `)}
`;


export const StyledVideo = styled.video`
  width: 100%;
  height: auto;
  display: block;
  background-color: #000;
  outline: none;
`;


export const PlayButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 12px;
  font-size: 20px;
  cursor: pointer;
  outline: none;
  z-index: 2;

  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }

  &:focus {
    outline: 2px solid ${({ theme }) => theme.colors.secondary};
    outline-offset: 2px;
  }

  ${media.mobile(`
    padding: 10px;
    font-size: 18px;
  `)}
`;


export const PurchaseOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding: 0 10px;

  ${media.mobile(`
    font-size: 16px;
    padding: 0 5px;
  `)}
`;


export const DownloadIconContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }

  &:focus {
    outline: 2px solid ${({ theme }) => theme.colors.accents[2]};
  }

  ${media.mobile(`
    bottom: 15px;
    right: 15px;
    padding: 6px;
  `)}

  ${({ isDownloadStarted }) =>
    isDownloadStarted &&
    `
    cursor: not-allowed;
    opacity: 0.6;
  `}
`;


export const SpinnerIcon = styled(FaSpinner)`
  animation: spin 1s linear infinite;
  color: #ffffff;
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export const Content = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.mobile(`
    padding: 12px;
  `)}
`;


export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${media.mobile(`
    flex-direction: column;
    align-items: flex-start;
  `)}
`;

export const Title = styled.h4`
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.darkBackground};
  margin: 10px 0;
  flex: 1;
  white-space: ${(props) => props.isPurchased ? 'nowrap' : 'normal'};
  overflow: ${(props) => props.isPurchased ? 'hidden' : 'visible'};
  text-overflow: ellipsis;

  ${media.mobile(`
    font-size: 16px;
    margin: 8px 0;
  `)}
`;

export const EditButton = styled.button`
  background: ${({ theme }) => theme.colors.accents[0]};
  border: none;
  color: ${({ theme }) => theme.colors.secondaryBackground};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.xs};
  margin-left: ${({ theme }) => theme.spacing.sm};
  border-radius: 0.375rem;
  transition: color 0.3s ease, background 0.3s ease;
  min-width: ${({ theme }) => theme.spacing.md};

  &:hover {
    background: ${({ theme }) => theme.colors.darkBackground};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primaryBackground};
  }

  ${media.mobile(`
    font-size: ${({ theme }) => theme.fontSizes.md};
    padding: ${({ theme }) => theme.spacing.sm};
    margin-left: 0;
    margin-top: 5px;
  `)}
`;

export const TrimButton = styled.button`
background: ${({ theme }) => theme.colors.accents[1]};
  border: none;
  color: ${({ theme }) => theme.colors.secondaryBackground};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.xs};
  margin-left: ${({ theme }) => theme.spacing.sm};
  border-radius: 0.375rem;
  transition: color 0.3s ease, background 0.3s ease;
  min-width: ${({ theme }) => theme.spacing.md};

  &:hover {
    background: ${({ theme }) => theme.colors.darkBackground};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primaryBackground};
  }

  ${media.mobile(`
    font-size: ${({ theme }) => theme.fontSizes.md};
    padding: ${({ theme }) => theme.spacing.sm};
    margin-left: 0;
    margin-top: 5px;
  `)}
    
`;

export const DeleteButton = styled.button`
  background: ${({ theme }) => theme.colors.secondary};
  border: none;
  color: ${({ theme }) => theme.colors.secondaryBackground};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.xs};
  margin-left: ${({ theme }) => theme.spacing.sm};
  border-radius: 0.375rem;
  transition: color 0.3s ease, background 0.3s ease;
  min-width: ${({ theme }) => theme.spacing.md};

  &:hover {
    background: ${({ theme }) => theme.colors.darkBackground};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primaryBackground};
  }

  ${media.mobile(`
    font-size: ${({ theme }) => theme.fontSizes.md};
    padding: ${({ theme }) => theme.spacing.sm};
    margin-left: 0;
    margin-top: 5px;
  `)}
`;


export const Description = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
  line-height: 1.4;

  ${media.mobile(`
    font-size: 13px;
    margin-bottom: 8px;
  `)}
`;


export const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  ${media.mobile(`
    margin-top: 8px;
  `)}
`;


export const ProgressBar = styled.div`
  height: 8px;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 4px;
  flex-grow: 1;
  transition: width 0.3s ease;
`;


export const ProgressLabel = styled.span`
  margin-left: 8px;
  font-size: 0.85rem;

  ${media.mobile(`
    font-size: 0.8rem;
    margin-left: 6px;
  `)}
`;


export const DownloadCompleteLabel = styled.span`
  color: #28a745;
  font-size: 0.85rem;
  margin-top: 10px;

  ${media.mobile(`
    font-size: 0.8rem;
    margin-top: 8px;
  `)}
`;

export const ErrorMessage = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.error};
  margin-top: 15px;

  ${media.mobile(`
    font-size: 13px;
    margin-top: 12px;
  `)}
`;

export const Message = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.error};
  margin-top: 15px;

  ${media.mobile(`
    font-size: 13px;
    margin-top: 12px;
  `)}
`;

export const StyledModalHeader = styled(Modal.Header)`
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  color: ${({ theme }) => theme.colors.text};
  border-bottom: none;
`;

export const StyledModalBody = styled(Modal.Body)`
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  color: ${({ theme }) => theme.colors.text};
`;

export const StyledModalFooter = styled(Modal.Footer)`
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  border-top: none;
`;

export const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 600px;

    @media (max-width: 768px) {
      max-width: 90%; // Make the modal width 90% of the viewport on small screens
    }
  }
`;

export const StyledTrimModalHeader = styled(Modal.Header)`
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  border-bottom: 2px solid ${({ theme }) => theme.colors.secondary};
  padding: 1.5rem 1rem;

  .modal-title {
    color: ${({ theme }) => theme.colors.darkBackground};
    font-size: 1.5rem;
    font-weight: bold;
  }
`;

export const StyledTrimModalBody = styled(Modal.Body)`
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  padding: 2rem 1.5rem;

  .video-trimmer-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

export const StyledTrimModalFooter = styled(Modal.Footer)`
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  border-top: 2px solid ${({ theme }) => theme.colors.secondary};
  padding: 1rem 1.5rem;
  justify-content: flex-end;

  .btn-secondary {
    background-color: ${({ theme }) => theme.colors.lightBackground};
    color: ${({ theme }) => theme.colors.text};
    border: 1px solid ${({ theme }) => theme.colors.border};
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    font-size: 1rem;

    &:hover {
      background-color: ${({ theme }) => theme.colors.secondaryBackground};
      border-color: ${({ theme }) => theme.colors.secondary};
    }

    &:focus {
      box-shadow: 0 0 0 3px rgba(100, 149, 237, 0.5);
    }
  }

  .btn-primary {
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    font-size: 1rem;

    &:hover {
      background-color: ${({ theme }) => theme.colors.secondary};
    }

    &:focus {
      box-shadow: 0 0 0 3px rgba(100, 149, 237, 0.5);
    }

    &:disabled {
      background-color: ${({ theme }) => theme.colors.disabledBackground};
      cursor: not-allowed;
    }
  }
`;


export const StyledFormControl = styled(Form.Control)`
  background-color: ${({ theme }) => theme.colors.inputBackground};
  color: ${({ theme }) => theme.colors.text};
  border: 1px solid ${({ theme }) => theme.colors.border};

  &:focus {
    background-color: ${({ theme }) => theme.colors.inputBackground};
    color: ${({ theme }) => theme.colors.text};
    border-color: ${({ theme }) => theme.colors.secondary};
    box-shadow: none;
  }
`;

export const StyledSaveButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary};
  border-color: ${({ theme }) => theme.colors.primary};

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.secondary};
    border-color: ${({ theme }) => theme.colors.secondary};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.disabledBackground};
    border-color: ${({ theme }) => theme.colors.disabledBorder};
    cursor: not-allowed;
  }
`;

export const StyledCancelButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.lightBackground};
  color: ${({ theme }) => theme.colors.text};
  border: none;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.secondaryBackground};
    color: ${({ theme }) => theme.colors.text};
  }
`;

export const ErrorText = styled.p`
  color: ${({ theme }) => theme.colors.error};
  margin-top: 10px;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0; 
  width: 100%; 
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

 export const ModalContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  padding: ${({ theme }) => theme.spacing.lg};
  border-radius: ${({ theme }) => theme.spacing.sm};
  width: 90%;
  max-width: ${({ theme }) => theme.breakpoints.md};
  box-shadow: ${({ theme }) => theme.shadows.large};
`;

export const ModalTitle = styled.h2`
  margin-bottom: ${({ theme }) => theme.spacing.md};
  font-family: ${({ theme }) => theme.fonts.primary};
`;

export const ModalBody = styled.p`
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  font-family: ${({ theme }) => theme.fonts.secondary};
`;

export const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing.sm};
`;

export const ConfirmButton = styled.button`
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  background-color: ${({ theme }) => theme.colors.error};
  color: #fff;
  border: none;
  border-radius: ${({ theme }) => theme.spacing.xs};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.primary};
  transition: ${({ theme }) => theme.transitions.button};

  &:hover {
    background-color: darken(${({ theme }) => theme.colors.error}, 10%);
  }
`;

export const CancelButton = styled(ConfirmButton)`
  background-color: ${({ theme }) => theme.colors.disabledBackground};
  color: ${({ theme }) => theme.colors.textSecondary};

  &:hover {
    background-color: darken(${({ theme }) => theme.colors.disabledBackground}, 10%);
  }
`;