// src/pages/LoginPage.js

import React from 'react';
import styled from 'styled-components';
import Login from '../components/common/Login';
import { media } from '../utils/media'; // Ensure this utility exists

const LoginPage = () => {
  return (
    <PageContainer>
      <Login />
    </PageContainer>
  );
};

export default LoginPage;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f7f7f7; /* Match Register's background */
  padding: 20px;

  ${media.tablet`
    padding: 15px;
  `}

  ${media.mobile`
    padding: 10px;
  `}
`;
