// src/components/Video/Pagination.js

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Helper function to generate page numbers with ellipsis
const getPageNumbers = (currentPage, totalPages, siblingCount = 1) => {
  const totalPageNumbers = siblingCount * 2 + 5;

  if (totalPages <= totalPageNumbers) {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }

  const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
  const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPages);

  const shouldShowLeftEllipsis = leftSiblingIndex > 2;
  const shouldShowRightEllipsis = rightSiblingIndex < totalPages - 1;

  const firstPageIndex = 1;
  const lastPageIndex = totalPages;

  const pages = [];

  pages.push(firstPageIndex);

  if (shouldShowLeftEllipsis) {
    pages.push('left-ellipsis');
  }

  for (let i = leftSiblingIndex; i <= rightSiblingIndex; i++) {
    pages.push(i);
  }

  if (shouldShowRightEllipsis) {
    pages.push('right-ellipsis');
  }

  pages.push(lastPageIndex);

  return pages;
};

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = getPageNumbers(currentPage, totalPages);

  if (totalPages === 0) return null;

  return (
    <PaginationContainer aria-label="Pagination Navigation">
      <PaginationButton
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        aria-label="Previous Page"
      >
        Previous
      </PaginationButton>

      {pageNumbers.map((page, index) => {
        if (page === 'left-ellipsis' || page === 'right-ellipsis') {
          return <Ellipsis key={index}>...</Ellipsis>;
        }

        return (
          <PageNumber
            key={page}
            active={page === currentPage}
            onClick={() => onPageChange(page)}
            aria-current={page === currentPage ? 'page' : undefined}
            aria-label={`Go to page ${page}`}
          >
            {page}
          </PageNumber>
        );
      })}

      <PaginationButton
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        aria-label="Next Page"
      >
        Next
      </PaginationButton>
    </PaginationContainer>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default React.memo(Pagination);

// Styled Components for Pagination

const PaginationContainer = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  flex-wrap: wrap;
`;

const PaginationButton = styled.button`
  background-color: #BD2026;
  color: #fff;
  border: none;
  padding: 8px 12px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  min-width: 80px;
  transition: background-color 0.3s ease;

  &:hover:not(:disabled) {
    background-color: #a31a22;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  &:focus {
    outline: 3px solid #80bdff;
    outline-offset: 2px;
  }
`;

const PageNumber = styled.button`
  background-color: ${(props) => (props.active ? '#a31a22' : '#fff')};
  color: ${(props) => (props.active ? '#fff' : '#BD2026')};
  border: 1px solid #BD2026;
  padding: 8px 12px;
  margin: 0 3px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  min-width: 40px;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover:not(.active) {
    background-color: #FF6F3C;
    color: #fff;
  }

  &:focus {
    outline: 3px solid #80bdff;
    outline-offset: 2px;
  }
`;

const Ellipsis = styled.span`
  padding: 8px 12px;
  margin: 0 3px;
  font-size: 14px;
  color: #666;
`;
