import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AuthService from '../components/Auth/AuthService';
import auth from '../utils/auth';

export const login = createAsyncThunk('auth/login', async ({ usernameOrEmail, password }, thunkApi) => {
  try {
    const { user, jwt: token } = await AuthService.login(usernameOrEmail, password);
    auth.setToken(token);
    auth.setUser(user);
    return { user, token };
  } catch (error) {
    return thunkApi.rejectWithValue(error.response?.data?.message || error.message);
  }
});

export const checkAuth = createAsyncThunk('auth/checkAuth', async (_, thunkAPI) => {
  const token = auth.getToken();
  if (token) {
    const user = AuthService.getCurrentUser();
    if (user) return { user, token };
  }
  return thunkAPI.rejectWithValue('No user found');
});

export const register = createAsyncThunk('auth/register', async (userData, thunkApi) => {
  try {
    const { user, jwt: token } = await AuthService.register(userData);
    auth.setToken(token);
    auth.setUser(user);
    return { user, token };
  } catch (error) {
    return thunkApi.rejectWithValue(error.response?.data?.message || error.message);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: auth.getUser() || null,
    token: auth.getToken() || null,
    loading: false,
    error: null,
  },
  reducers: {
    logoutSuccess: (state) => {
      state.user = null;
      state.token = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(register.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
      })
      .addCase(register.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { logoutSuccess } = authSlice.actions;

export const logout = () => (dispatch) => {
  auth.clearAuth();
  dispatch(logoutSuccess());
};

export default authSlice.reducer;
