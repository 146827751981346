import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import useVideoDetails from './UseVideoDetails';
import { downloadVideo } from '../../store/videoSlice';
import { formatDate, formatDuration } from './VideoCard';

const VideoDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const videoId = new URLSearchParams(location.search).get('id');
  const { videoDetails, isPurchased, isLoading, error } = useVideoDetails(videoId);

  const handleTrimAndPurchase = () => {
    navigate(`/trim-video?id=${videoId}`);
  };

  const handleDownload = () => {
    if (isPurchased) {
      dispatch(downloadVideo(videoId));
    }
  };

  if (isLoading) return <LoadingMessage>Loading video details...</LoadingMessage>;
  if (error) return <ErrorMessage>{error}</ErrorMessage>;
  if (!videoDetails) return <LoadingMessage>Loading video details...</LoadingMessage>;

  return (
    <VideoDetailsContainer>
      <Logo src="/path-to-logo/logo.png" alt="Logo" />
      <VideoTitle>
        {`Akiture_Video_${videoDetails.id} - ${formatDate(videoDetails.uploadDate)}: ${formatDuration(videoDetails.duration)}`}
      </VideoTitle>
      <VideoPlayerContainer>
        <VideoPlayer controls={isPurchased} poster={videoDetails.posterUrl} disabled={!isPurchased}>
          <source src={videoDetails.preSignedS3Url} type="video/mp4" />
          Your browser does not support the video tag.
        </VideoPlayer>
        {!isPurchased && (
          <Overlay>
            <OverlayMessage>This video is locked. Please purchase to watch.</OverlayMessage>
            <OverlayButton onClick={handleTrimAndPurchase}>Purchase</OverlayButton>
          </Overlay>
        )}
      </VideoPlayerContainer>
      <VideoDescription>{videoDetails.description}</VideoDescription>
      <ButtonContainer>
        <ActionButton onClick={handleTrimAndPurchase} disabled={isPurchased}>
          {isPurchased ? 'Purchased' : 'Purchase'}
        </ActionButton>
        <ActionButton onClick={handleDownload} disabled={!isPurchased}>
          Download
        </ActionButton>
      </ButtonContainer>
    </VideoDetailsContainer>
  );
};

export default VideoDetails;

const VideoDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f0f2f5;
  text-align: center;
  width: 100%;
`;

const Logo = styled.img`
  width: 150px;
  margin-bottom: 20px;
`;

const VideoTitle = styled.h2`
  margin-bottom: 15px;
  font-size: 28px;
  font-weight: bold;
  color: #333;
`;

const VideoPlayerContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
`;

const VideoPlayer = styled.video`
  width: 100%;
  height: auto;
  border-radius: 12px;
  background-color: #000;

  &:disabled {
    filter: brightness(0.7);
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 12px;
`;

const OverlayMessage = styled.p`
  color: #fff;
  font-size: 18px;
  margin-bottom: 15px;
`;

const OverlayButton = styled.button`
  padding: 12px 25px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #c41e3a;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #a0001a;
  }
`;

const VideoDescription = styled.p`
  font-size: 16px;
  color: #555;
  margin: 15px 0;
  line-height: 1.6;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
`;

const ActionButton = styled.button`
  padding: 10px 25px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #c41e3a;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #a0001a;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const LoadingMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 18px;
  color: #666;
`;

const ErrorMessage = styled.div`
  font-size: 18px;
  color: red;
  text-align: center;
`;
