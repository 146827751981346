// src/components/MissionSection.js

import React from 'react';
import styled from 'styled-components';
import { FaRocket } from 'react-icons/fa';
import { Section, HorizontalLine, IconWrapper, SectionTitle } from './common/Sections';
import MissionImage from '../assets/images/mission.jpg';

const MissionSection = () => {
  return (
    <Section id="mission">
      <ContentBox>
        <Header>
          <IconWrapper>
            <FaRocket aria-hidden="true" />
          </IconWrapper>
          <SectionTitle>Our Mission</SectionTitle>
          <HorizontalLine />
        </Header>
        <ContentRow>
          <ContentWrapper>
            <MissionText>
              We aim to empower every athlete by making high-quality sports footage accessible to all,
              while fostering a strong, connected local sports community. Your moments deserve to be
              celebrated, studied, and shared without barriers.
            </MissionText>
          </ContentWrapper>
          <ImageWrapper>
            <StyledImage
              src={MissionImage}
              alt="Athletes celebrating on the field"
              loading="lazy"
            />
          </ImageWrapper>
        </ContentRow>
      </ContentBox>
    </Section>
  );
};

export default MissionSection;

// Styled Components

const ContentBox = styled.div`
  max-width: 1200px;
  margin: 0 auto 4rem auto;
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.darkBackground};
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  box-shadow: ${({ theme }) => theme.shadows.medium};
  border: 1px solid ${({ theme }) => theme.colors.accents[2]};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 1.5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 1rem;
    margin-bottom: 2rem;
  }
`;

const Header = styled.div`
  text-align: center;
`;

const ContentRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.lg};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    margin-top: ${({ theme }) => theme.spacing.md};
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  margin-right: ${({ theme }) => theme.spacing.lg};
  text-align: left;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-right: 0;
    margin-bottom: ${({ theme }) => theme.spacing.md};
    text-align: center;
  }
`;

const MissionText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.lg};
  line-height: 1.75;
  color: ${({ theme }) => theme.colors.secondaryBackground};
  margin-top: ${({ theme }) => theme.spacing.sm};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fontSizes.sm};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.fontSizes.xs};
  }
`;

const ImageWrapper = styled.div`
  flex: 1;
  margin-top: ${({ theme }) => theme.spacing.sm};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImage = styled.img`
  width: 100%;
  max-width: 500px;
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  box-shadow: ${({ theme }) => theme.shadows.small};
  transition: ${({ theme }) => theme.transitions.default};
  border: 2px solid ${({ theme }) => theme.colors.border};

  &:hover,
  &:focus {
    transform: scale(1.02);
    box-shadow: ${({ theme }) => theme.shadows.large};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 100%;
  }
`;
