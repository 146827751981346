import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../utils/api';
import { COLORS } from '../BrandColors';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);
  const token = query.get('token');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    try {
      const response = await api.post(`/auth/reset`, { token, newPassword });
      console.log('Reset Password Response:', response.data);

      setMessage(response.data);
      setError('');
      // Redirect to login after a short delay
      setTimeout(() => navigate('/login'), 3000);
    } catch (err) {
      setError(err.response?.data || 'An error occurred');
      setMessage('');
    }
  };

  useEffect(() => {
    if (!token) {
      setError('Invalid or missing token');
    }
  }, [token]);

  return (
    <ResetPasswordContainer>
      <Heading>Reset Password</Heading>
      {token ? (
        <Form onSubmit={handleSubmit}>
          <Label htmlFor="newPassword">New Password:</Label>
          <Input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <Label htmlFor="confirmPassword">Confirm Password:</Label>
          <Input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <Button type="submit">Update Password</Button>
        </Form>
      ) : (
        <ErrorMessage>Invalid or missing token</ErrorMessage>
      )}
      {message && <SuccessMessage>{message}</SuccessMessage>}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </ResetPasswordContainer>
  );
};

export default ResetPassword;

const ResetPasswordContainer = styled.div`
  background-color: ${COLORS.primaryBackground};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`;

const Heading = styled.h2`
  color: ${COLORS.accentBlueGray};
  margin-bottom: 1.5rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
`;

const Label = styled.label`
  color: ${COLORS.accentBlueGray};
  margin-bottom: 0.5rem;
`;

const Input = styled.input`
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid ${COLORS.accentBlueGray};
  border-radius: 4px;
  font-size: 1rem;

  &:focus {
    outline: none;
    border-color: ${COLORS.accentTeal};
    box-shadow: 0 0 0 2px rgba(0, 166, 147, 0.2);
  }
`;

const Button = styled.button`
  background-color: ${COLORS.secondaryButton};
  color: ${COLORS.primaryBackground};
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 1rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${COLORS.accentOrange};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 111, 60, 0.2);
  }
`;

const SuccessMessage = styled.p`
  color: ${COLORS.accentTeal};
  font-size: 1rem;
`;

const ErrorMessage = styled.p`
  color: ${COLORS.secondaryButton};
  font-size: 1rem;
`;
