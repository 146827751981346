// src/components/Unauthorized.jsx

import React from 'react';
import { Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Unauthorized = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Unauthorized Access
      </Typography>
      <Typography variant="body1" gutterBottom>
        You do not have permission to view this page.
      </Typography>
      <Button variant="contained" color="primary" component={Link} to="/">
        Go to Home
      </Button>
    </Container>
  );
};

export default Unauthorized;

// Styled Components
const Container = styled.div`
  padding: 40px;
  text-align: center;
`;
