// src/components/common/AuthLayout.js

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types'; // Import PropTypes for type-checking
import Header from './Header'; // Minimal header for auth pages
import Logo from './Logo'; // Import the Logo component
import { media } from '../../utils/media';

const AuthLayout = ({ children, headerProps }) => {
  return (
    <AuthPageContainer>
      <LogoWrapper>
        <Logo size="large" alt="Company Logo" /> {/* Render the Logo */}
      </LogoWrapper>
      <Header {...headerProps} />
      <AuthContentWrapper>
        {children}
      </AuthContentWrapper>
    </AuthPageContainer>
  );
};

// Define PropTypes for better type-checking
AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
  headerProps: PropTypes.object,
};

// Define defaultProps in case headerProps is not provided
AuthLayout.defaultProps = {
  headerProps: {},
};

export default AuthLayout;

// Styled-components

const AuthPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Centers content vertically */
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  padding: 20px;

  ${media.tablet(`
    padding: 15px;
  `)}

  ${media.mobile(`
    padding: 10px;
  `)}
`;

const AuthContentWrapper = styled.main`
  width: 100%;
  max-width: 500px;
`;

const LogoWrapper = styled.div`
  margin-bottom: 1.5rem;
`;
