import { createSlice } from '@reduxjs/toolkit';
import auth from '../utils/auth';

// Initial state setup with persisted data
const initialState = {
  user: auth.getUser() || null, // Get user from localStorage (if available)
  loading: false,
  error: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.loading = false;
      state.error = null;
      auth.setUser(action.payload); // Persist user in localStorage
    },
    clearUser: (state) => {
      state.user = null;
      state.loading = false;
      state.error = null;
      auth.clearAuth(); // Clear user data and token
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    updateUserField: (state, action) => {
      // Useful for updating a single field, like profile updates
      if (state.user) {
        state.user = { ...state.user, ...action.payload };
        auth.setUser(state.user); // Persist updated user
      }
    },
  },
});

export const { setUser, clearUser, setLoading, setError, updateUserField } = userSlice.actions;

export default userSlice.reducer;
