// src/components/VerifyEmail.js

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import api from '../utils/api';

const VerifyEmail = () => {
  const [message, setMessage] = useState('');
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await api.get(`/users/verify-email?token=${token}`);
        setMessage(response.data);
      } catch (error) {
        setMessage(error.response.data);
      }
    };

    verifyEmail();
  }, [token]);

  return (
    <div>
      <h2>{message}</h2>
    </div>
  );
};

export default VerifyEmail;
