import Cookies from 'js-cookie';

const TOKEN_KEY = 'jwt_token';
const USER_KEY = 'user';

// Centralized cookie options
const cookieOptions = {
  secure: false,
  sameSite: 'Lax',
  path: '/',
};

// Set token in cookies
const setToken = (token) => {
  try {
    Cookies.set(TOKEN_KEY, token, cookieOptions);
    console.log('Token set to cookies:', token);
  } catch (error) {
    console.error('Failed to set token:', error);
  }
};

// Get token from cookies
const getToken = () => {
  try {
    const token = Cookies.get(TOKEN_KEY);
    console.log('Retrieved Token:', token);
    return token;
  } catch (error) {
    console.error('Failed to retrieve token:', error);
    return null;
  }
};

// Remove token from cookies
const removeToken = () => {
  try {
    Cookies.remove(TOKEN_KEY, cookieOptions);
    console.log('Token removed from cookies');
  } catch (error) {
    console.error('Failed to remove token:', error);
  }
};

// Remove user data from local storage
const removeUser = () => {
  try {
    localStorage.removeItem(USER_KEY);
    console.log('User data removed from local storage');
  } catch (error) {
    console.error('Failed to remove user data:', error);
  }
};

// Optional: Add user management if needed
const setUser = (user) => {
  try {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
    console.log('User data set to local storage:', user);
  } catch (error) {
    console.error('Failed to set user data:', error);
  }
};

const getUser = () => {
  try {
    const user = JSON.parse(localStorage.getItem(USER_KEY));
    console.log('Retrieved User:', user);
    return user;
  } catch (error) {
    console.error('Failed to retrieve user data:', error);
    return null;
  }
};

// Clear all authentication data
const clearAuth = () => {
  removeToken();
  removeUser();
};

// Export the auth utility
const auth = {
  setToken,
  getToken,
  removeToken,
  setUser,
  getUser,
  removeUser,
  clearAuth,
};

export default auth;
