import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import api from '../utils/api';
import styled from 'styled-components';
import { CircularProgress, TextField, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { media } from '../utils/media';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const ConfirmationPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [processId, setProcessId] = useState(null);
  const user = useSelector((state) => state.auth.user);

  // New state variables for discount code
  const [discountCode, setDiscountCode] = useState('');
  const [isDiscountValid, setIsDiscountValid] = useState(null);
  const [discountError, setDiscountError] = useState('');

  // Extract state passed from navigation
  const { videoIds = [], thumbnails = [] } = location.state || {};
  const isValidData = useMemo(() => videoIds.length > 0 && thumbnails.length > 0, [videoIds, thumbnails]);

  const totalRequestedSeconds = useMemo(() => {
    const totalLengthOfOneVideoInSeconds = 900; // 15 minutes
    return videoIds.length * totalLengthOfOneVideoInSeconds;
  }, [videoIds]);

  const formatTime = useCallback((seconds) => {
    const hours = String(Math.floor(seconds / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
    return `${hours}:${minutes}`;
  }, []);

  const formattedTotalDuration = formatTime(totalRequestedSeconds);

  const calculatePrice = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/videos/calculate-price', {
        params: {
          videoIds: videoIds.join(','),
          duration: totalRequestedSeconds,
        },
      });
      setPrice(response.data.price);
    } catch (error) {
      setMessage('Failed to calculate price. Please ensure your request is correct and try again.');
      console.error('Price calculation error:', error);
    } finally {
      setLoading(false);
    }
  }, [videoIds, totalRequestedSeconds]);

  useEffect(() => {
    if (isValidData) {
      calculatePrice();
    }
  }, [isValidData, calculatePrice]);

  const validateDiscountCode = useCallback(async () => {
    try {
      setLoading(true);
      setDiscountError('');
      const response = await api.get('/admin/discount-codes/validate', {
        params: {
          code: discountCode,
          videoIds: videoIds.join(','),
          totalRequestedDurationInSeconds: totalRequestedSeconds,
        },
      });
      console.log(response.data);
      if (response.data.valid) {
        setIsDiscountValid(true);
        setMessage('Discount code applied successfully!');
        setPrice(response.data.price); // Update the price with the discounted price
      } else {
        setIsDiscountValid(false);
        setDiscountError('Invalid or expired discount code.');
      }
    } catch (error) {
      setIsDiscountValid(false);
      setDiscountError('Error validating discount code.');
      console.error('Discount code validation error:', error);
    } finally {
      setLoading(false);
    }
  }, [discountCode, videoIds, totalRequestedSeconds]);

  const handlePayment = useCallback(async () => {
    if (!user) {
      navigate('/register', {
        state: {
          from: location.pathname,
          videoIds,
          duration: totalRequestedSeconds,
          thumbnails,
          processing: true,
        },
      });
      return;
    }

    if (!isValidData) {
      setMessage('Cannot proceed to payment due to invalid data. Please check your selections.');
      return;
    }

    try {
      setLoading(true);
      const stripe = await stripePromise;
      const response = await api.post('/videos/payment/checkout-session', {
        videoIds,
        userId: user.id,
        totalRequestedDuration: totalRequestedSeconds,
        successUrl: `${window.location.origin}/dashboard`,
        cancelUrl: `${window.location.origin}/confirmation`,
        discountCode: isDiscountValid ? discountCode : null,
      });

      const { id: sessionId, processId } = response.data;
      console.log('Process ID:', processId);
      setProcessId(processId); // Store processId in state.

      const result = await stripe.redirectToCheckout({ sessionId });

      if (result.error) {
        setMessage(`Error redirecting to checkout: ${result.error.message}`);
      }
    } catch (error) {
      setMessage('Error initiating payment. Please try again.');
      console.error('Payment initiation error:', error);
    } finally {
      setLoading(false);
    }
  }, [user, videoIds, totalRequestedSeconds, isValidData, navigate, location.pathname, thumbnails, discountCode, isDiscountValid]);

  return (
    <ConfirmationContainer>
      <ContentWrapper>
        <h1>Confirmation</h1>
        <TimeRange>Total Requested Duration: {formattedTotalDuration}</TimeRange>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <PriceTag>Price: ${price.toFixed(2)}</PriceTag>
        )}

        <DiscountContainer>
          <TextField
            label="Discount Code"
            variant="outlined"
            value={discountCode}
            onChange={(e) => setDiscountCode(e.target.value)}
            disabled={loading}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={validateDiscountCode}
            disabled={loading || !discountCode}
          >
            Apply
          </Button>
        </DiscountContainer>
        {discountError && <ErrorMessage>{discountError}</ErrorMessage>}
        {message && !discountError && <SuccessMessage>{message}</SuccessMessage>}

        {thumbnails.length > 0 && (
          <CourtImage src={thumbnails[0] || '/fallback-thumbnail.png'} alt="Selected Video Thumbnail" />
        )}
        <PaymentButton onClick={handlePayment} disabled={loading || !isValidData}>
          {loading ? <CircularProgress size={24} /> : 'Proceed to Payment'}
        </PaymentButton>
        {message && <ErrorMessage>{message}</ErrorMessage>}
      </ContentWrapper>
    </ConfirmationContainer>
  );
};

export default ConfirmationPage;

// Styled Components
const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  padding: 20px;

  ${media.tablet(`
    padding: 30px;
  `)}

  ${media.mobile(`
    padding: 15px;
  `)}
`;


const ContentWrapper = styled.div`
  text-align: center;
  padding: 40px 20px;
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  border-radius: 10px;
  margin: 40px auto;
  max-width: 600px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  ${media.tablet(`
    padding: 30px 20px;
    max-width: 500px;
    margin: 30px auto;
  `)}

  ${media.mobile(`
    padding: 20px 15px;
    max-width: 90%;
    margin: 20px auto;
  `)}
`;


const TimeRange = styled.p`
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;

  ${media.mobile(`
    font-size: 16px;
  `)}
`;


const PriceTag = styled.p`
  font-size: 22px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary}; /* Use theme color */
  margin-bottom: 20px;

  ${media.mobile(`
    font-size: 20px;
  `)}
`;


const CourtImage = styled.img`
  width: 80%;
  max-width: 400px;
  height: auto;
  margin: 20px 0;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  ${media.tablet(`
    max-width: 300px;
  `)}

  ${media.mobile(`
    width: 100%;
    max-width: 250px;
    margin: 15px 0;
  `)}
`;


const PaymentButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
  padding: 14px 24px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 300px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);

  &:hover {
    background-color: #a31a22;
    transform: translateY(-2px);
  }

  &:active {
    background-color: #8e1620;
    transform: translateY(0);
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
    transform: none;
  }

  &:focus {
    outline: 3px solid ${({ theme }) => theme.colors.accentOrange};
    outline-offset: 2px;
  }

  ${media.mobile(`
    font-size: 14px;
    padding: 12px 20px;
    max-width: 250px;
  `)}
`;


const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.primary}; /* Use theme color */
  margin-top: 15px;
  font-size: 16px;
  text-align: center;
  max-width: 500px;

  ${media.mobile(`
    font-size: 14px;
    max-width: 90%;
  `)}
`;

const SuccessMessage = styled.p`
  color: #28a745; /* Green color for success messages */
  margin-top: 15px;
  font-size: 16px;
  text-align: center;
  max-width: 500px;

  ${media.mobile(`
    font-size: 14px;
    max-width: 90%;
  `)}
`;


const NavBarSpacing = styled.div`
  padding-top: 60px;
`;

const DiscountContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;

  ${media.tablet(`
    flex-direction: column;
    gap: 15px;
  `)}

  ${media.mobile(`
    flex-direction: column;
    gap: 10px;
    margin-bottom: 15px;
  `)}
`;


