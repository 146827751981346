// src/components/common/AuthStyles.js

import styled from 'styled-components';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { media } from '../../utils/media'; // Ensure this utility exists

// Container for Auth Pages
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #f7f7f7; /* Match Register's background */
  min-height: 100vh;

  ${media.tablet`
    padding: 1.5rem;
  `}

  ${media.mobile`
    padding: 1rem;
  `}
`;

// Styled Form
export const StyledForm = styled.form`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  
  ${media.mobile`
    padding: 1.5rem;
  `}
`;

// Logo Wrapper
export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

// Logo Image
export const Logo = styled.img`
  width: 120px; /* Adjust size as needed */
  height: auto;
`;

// Form Title
export const FormTitle = styled.h2`
  font-size: 1.75rem; /* Equivalent to '2xl' */
  color: #333;
  margin-bottom: 0.5rem;
  text-align: center;
`;

// Form Subtitle
export const FormSubtitle = styled.p`
  font-size: 1rem; /* Equivalent to 'md' */
  color: #666;
  margin-bottom: 2rem;
  text-align: center;
`;

// Form Group
export const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

// Label
export const Label = styled.label`
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #333;
`;

// Input
export const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  background-color: #f9f9f9;
  transition: border-color 0.3s, box-shadow 0.3s;

  &:focus {
    border-color: #e63946; /* Brand red color */
    outline: none;
    box-shadow: 0 0 0 2px rgba(230, 57, 70, 0.2); /* Subtle shadow */
  }

  &:disabled {
    background-color: #eaeaea;
    cursor: not-allowed;
  }
`;

// Password Wrapper
export const PasswordWrapper = styled.div`
  position: relative;
`;

// Toggle Button for Password Visibility
export const ToggleButton = styled.button`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  font-size: 1rem;

  &:focus {
    outline: none;
    color: #e63946; /* Highlight color on focus */
  }
`;

// Form Error
export const FormError = styled.div`
  color: #e63946;
  font-size: 0.875rem;
  margin-top: 0.25rem;
`;

// Button Group
export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
`;

// Primary Button
export const Button = styled.button`
  padding: 0.75rem;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  background-color: #e63946; /* Brand red color */
  color: white;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s, transform 0.2s;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:hover:not(:disabled) {
    background-color: #d62839;
    transform: translateY(-2px);
  }

  &:active:not(:disabled) {
    background-color: #e63946;
    transform: translateY(0);
  }
`;

// Secondary Button
export const SecondaryButton = styled.button`
  padding: 0.75rem;
  font-size: 1rem;
  cursor: pointer;
  background-color: transparent;
  color: #333;
  border: 1px solid #333;
  border-radius: 4px;
  transition: color 0.3s, border-color 0.3s, transform 0.2s;

  &:hover {
    color: #e63946;
    border-color: #e63946;
    transform: translateY(-2px);
  }

  &:active {
    color: #e63946;
    border-color: #e63946;
    transform: translateY(0);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

// Error Message
export const Error = styled.div`
  color: #e63946;
  margin-top: 1rem;
  font-size: 1rem;
  text-align: center;
`;

// Success Message
export const Success = styled.div`
  color: #2a9d8f; /* Success color */
  margin-top: 1rem;
  font-size: 1rem;
  text-align: center;
`;
