import React from 'react';
import styled from 'styled-components';
import Register from '../components/common/Register';

const RegisterPage = () => {
    return (
        <PageContainer>
            <Register />
        </PageContainer>
    );
}

export default RegisterPage;

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f9f9f9;
    padding: 20px;

    @media (max-width: 768px) {
        padding: 15px;
    }
`;
