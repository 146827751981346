// src/components/common/Footer.js

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FaLinkedin, FaInstagram } from 'react-icons/fa';
import FooterLogo from '../../assets/images/2.svg';

const Footer = () => {
  useEffect(() => {
    if (!document.getElementById('termly-jssdk')) {
      const script = document.createElement('script');
      script.id = 'termly-jssdk';
      script.type = 'text/javascript';
      script.src = 'https://app.termly.io/embed-policy.min.js';
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  return (
    <FooterContainer>
      <FooterContent>
        <LogoSection>
          <FooterLogoStyled src={FooterLogo} alt="Akture Footer Logo" />
          <LogoTextContainer>
            <LogoText>Akture</LogoText>
            <LogoSubtitle>Action Capture System</LogoSubtitle>
          </LogoTextContainer>
        </LogoSection>

        <LinksSection>
          <SectionTitle>Explore</SectionTitle>
          <FooterLinkList>
            <FooterLinkItem>
              <FooterLink href="https://akture.video" target="_blank" rel="noopener noreferrer">
                Home
              </FooterLink>
            </FooterLinkItem>
            <FooterLinkItem>
              <FooterLink href="https://akture.video/explore" target="_blank" rel="noopener noreferrer">
                Purchase
              </FooterLink>
            </FooterLinkItem>
            <FooterLinkItem>
              <FooterLink href="mailto:contact@akture.video">
                Contact
              </FooterLink>
            </FooterLinkItem>
          </FooterLinkList>
        </LinksSection>

        <PolicySection>
          <SectionTitle>Policies</SectionTitle>
          <FooterLinkList>
            <FooterLinkItem>
              <FooterLink href="https://app.termly.io/policy-viewer/policy.html?policyUUID=13d638fb-9167-4a7b-8b68-eb366e975d10" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </FooterLink>
            </FooterLinkItem>
            {/* Add other policy links as needed */}
          </FooterLinkList>
        </PolicySection>

        <SocialSection>
          <SocialIcon href="https://linkedin.com/company/crazed-entertainment/" aria-label="LinkedIn" target="_blank" rel="noopener noreferrer">
            <FaLinkedin />
          </SocialIcon>
          <SocialIcon href="https://instagram.com/AktureSports" aria-label="Instagram" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </SocialIcon>
        </SocialSection>
      </FooterContent>

      <FooterBottom>
        <p>Contact us at contact@akture.video</p>
        &copy; {new Date().getFullYear()} Akture. All rights reserved.
      </FooterBottom>
    </FooterContainer>
  );
};

export default Footer;

// Styled Components

const FooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.colors.darkBackground};
  color: #fff;
  padding: 2rem 1rem;
  width: 100%;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
`;

const LogoSection = styled.div`
  flex: 1 1 200px;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const FooterLogoStyled = styled.img`
  width: 50px;
  height: auto;
  margin-right: 1rem;
`;

const LogoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LogoText = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
  color: #bd2026;
`;

const LogoSubtitle = styled.span`
  font-size: 0.9rem;
  color: #fff;
  margin-top: 0.25rem;
`;

const LinksSection = styled.div`
  flex: 1 1 150px;
  margin-bottom: 1.5rem;
`;

const PolicySection = styled.div`
  flex: 1 1 150px;
  margin-bottom: 1.5rem;
`;

const SectionTitle = styled.h4`
  font-size: 1.2rem;
  margin-bottom: 0.75rem;
  color: #fff;
`;

const FooterLinkList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const FooterLinkItem = styled.li`
  margin-bottom: 0.5rem;
`;

const FooterLink = styled.a`
  color: #ccc;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: #bd2026;
    text-decoration: underline;
  }
`;

const SocialSection = styled.div`
  flex: 1 1 150px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

const SocialIcon = styled.a`
  color: #ccc;
  font-size: 1.5rem;
  transition: color 0.3s ease;

  &:hover {
    color: #bd2026;
  }
`;

const FooterBottom = styled.div`
  border-top: 1px solid #444;
  padding-top: 1rem;
  font-size: 0.9rem;
  color: #aaa;
  text-align: center;
`;