// src/components/common/Logo.js

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Logo = ({ size = 'medium', alt = 'Company Logo' }) => {
  return (
    <LogoImage size={size} src={`${process.env.PUBLIC_URL}/1.svg`} alt={alt} />
  );
};

Logo.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  alt: PropTypes.string,
};

export default Logo;

const LogoImage = styled.img`
  width: ${({ theme, size }) => theme.sizes.logo[size] || theme.sizes.logo.medium};
  height: auto;
  object-fit: contain;
  max-height: 500px;
  max-width: 600px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: ${({ theme }) => theme.sizes.logo.small};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) and (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    width: ${({ theme }) => theme.sizes.logo.medium};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    width: ${({ theme }) => theme.sizes.logo.large};
  }
`;
