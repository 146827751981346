// src/styles/brandColors.js

export const COLORS = {
    primaryBackground: '#FFFFFF', // White
    footer: '#010101',            // Almost Black
    secondaryButton: '#BD2026',   // CTA Red
    accentTeal: '#00A693',
    accentOrange: '#FF6F3C',
    accentBlueGray: '#5A7684',
  };
  