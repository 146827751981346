import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVideoDetails } from '../../store/videoSlice';
import api from '../../utils/api';

const useVideoDetails = (videoId) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.user?.id);
  const videoDetails = useSelector((state) => state.video.videoDetails);
  const [isPurchased, setIsPurchased] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadVideoDetails = async () => {
      try {
        setIsLoading(true);
        setError(null);

        // Fetch video details
        if (videoId) {
          await dispatch(fetchVideoDetails(videoId));
        }

        // Check purchase status
        if (userId && videoId) {
          const response = await api.get(`/videos/isPurchased/${videoId}`, {
            params: { userId },
          });
          setIsPurchased(response.data);
        }
      } catch (error) {
        setError('Failed to load video details or check purchase status');
      } finally {
        setIsLoading(false);
      }
    };

    if (videoId) {
      loadVideoDetails();
    }
  }, [dispatch, videoId, userId]);

  return {
    videoDetails,
    isPurchased,
    error,
    isLoading,
  };
};

export default useVideoDetails;
