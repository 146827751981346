import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/api'; 


// Async thunk for downloading video with immediate download progress
export const downloadVideo = createAsyncThunk(
  'video/download',
  async (videoId, thunkAPI) => {
    try {
      // Reset download progress for this video when download starts
      thunkAPI.dispatch(setDownloadProgress({ videoId, progress: 0 }));

      const response = await api.get(`/videos/download/${videoId}`, {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;

          if (total) {
            const progress = Math.round((loaded / total) * 100);
            thunkAPI.dispatch(setDownloadProgress({ videoId, progress }));
          } else {
            // Handle the case where Content-Length is missing or zero
            thunkAPI.dispatch(setDownloadProgress({ videoId, progress: -1 }));
          }
        }
      });

      // Create a blob URL and initiate download
      const blob = new Blob([response.data], { type: response.data.type || 'video/mp4' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `video_${videoId}.mp4`);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      return videoId; // Return videoId on success
    } catch (error) {
      console.error('Error downloading video:', error);
      return thunkAPI.rejectWithValue(`Failed to download video ${videoId}. Please try again.`);
    }
  }
);

// Async thunk for fetching trimmed video
export const fetchTrimmedVideo = createAsyncThunk(
  'video/fetchTrimmedVideo',
  async (videoId, thunkAPI) => {
    try {
      const response = await api.get(`/videos/${videoId}/trimmed`);
      return response.data;
    } catch (error) {
      console.error('Error fetching trimmed video:', error);
      return thunkAPI.rejectWithValue(`Failed to fetch trimmed video ${videoId}.`);
    }
  }
);

// Async thunk for fetching videos
export const fetchVideos = createAsyncThunk('video/fetchVideos', async () => {
  try {
    const response = await api.get('/videos/meta');
    console.log('Fetched videos:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching videos:', error);
    throw new Error('Failed to fetch videos. Please try again.');
  }
});

export const fetchUserPurchasedVideos = createAsyncThunk('video/fetchUserPurchasedVideos',
   async (userId) => {
  try {
    const response = await api.get(`/users/${userId}/purchased-videos`);
    console.log('Fetched user purchased videos:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching user purchased videos:', error);
    throw new Error('Failed to fetch purchased videos. Please try again.');
  }
});

// Async thunk for fetching video details
export const fetchVideoDetails = createAsyncThunk(
  'video/fetchVideoDetails',
  async (id, thunkAPI) => {
    try {
      const response = await api.get(`/videos/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching video details:', error);
      return thunkAPI.rejectWithValue('Failed to fetch video details.');
    }
  }
);

const videoSlice = createSlice({
  name: 'video',
  initialState: {
    videos: [],
    videoDetails: null,
    trimmedVideos: [],
    userPurchasedVideos: [],
    downloadProgress: {}, // Use an object to track progress for each video
    downloadStatus: {}, // Track status for each video
    loading: {
      fetchVideos: false,
      fetchVideoDetails: false,
      downloadVideo: false,
      trimmedVideos: false,
      fetchUserPurchasedVideos: false,
    },
    error: null,
  },
  reducers: {
    setDownloadProgress(state, action) {
      const { videoId, progress } = action.payload;
      state.downloadProgress[videoId] = progress;
    },

    resetDownloadProgress(state, action) {
      const videoId = action.payload;
      delete state.downloadProgress[videoId];
      delete state.downloadStatus[videoId];
    },

    setVideos(state, action) {
      state.videos = action.payload.reduce((acc, video) => {
        acc[video.id] = video;
        return acc;
      }, {});
      state.videoIds = action.payload.map((video) => video.id);
    },



    resetVideoState(state) {
      state.videos = [];
      state.videoDetails = null;
      state.trimmedVideos = [];
      state.userPurchasedVideos = [];
      state.loading = {
        fetchVideos: false,
        fetchVideoDetails: false,
        downloadVideo: false,
        trimmedVideos: false,
        fetchUserPurchasedVideos: false,
      };
      state.downloadProgress = {};
      state.downloadStatus = {};
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVideos.pending, (state) => {
        state.loading.fetchVideos = true;
        state.error = null;
      })
      .addCase(fetchVideos.fulfilled, (state, action) => {
        state.loading.fetchVideos = false;
        state.videos = action.payload;
      })
      .addCase(fetchVideos.rejected, (state, action) => {
        state.loading.fetchVideos = false;
        state.error = action.payload || 'Failed to fetch videos. Please try again.';
      })

      .addCase(fetchVideoDetails.pending, (state) => {
        state.loading.fetchVideoDetails = true;
        state.error = null;
      })
      .addCase(fetchVideoDetails.fulfilled, (state, action) => {
        state.loading.fetchVideoDetails = false;
        state.videoDetails = action.payload;
      })
      .addCase(fetchVideoDetails.rejected, (state, action) => {
        state.loading.fetchVideoDetails = false;
        state.error = action.payload;
      })

      // Immediate status updates for downloads
      .addCase(downloadVideo.pending, (state, action) => {
        const videoId = action.meta.arg;
        state.loading.downloadVideo = true;
        state.downloadStatus[videoId] = 'loading';
        state.downloadProgress[videoId] = 0;
        state.error = null;
      })
      .addCase(downloadVideo.fulfilled, (state, action) => {
        const videoId = action.payload;
        state.loading.downloadVideo = false;
        state.downloadStatus[videoId] = 'success';
        state.downloadProgress[videoId] = 100; // Mark as fully downloaded
      })
      .addCase(downloadVideo.rejected, (state, action) => {
        const videoId = action.meta.arg;
        state.loading.downloadVideo = false;
        state.downloadStatus[videoId] = 'failed';
        state.downloadProgress[videoId] = 0;
        state.error = action.payload;
      })
      .addCase(fetchTrimmedVideo.pending, (state) => {
        state.loading.trimmedVideos = true;
        state.error = null;
      })
      .addCase(fetchTrimmedVideo.fulfilled, (state, action) => {
        state.loading.trimmedVideos = false;
        state.trimmedVideos = action.payload;
      })
      .addCase(fetchTrimmedVideo.rejected, (state, action) => {
        state.loading.trimmedVideos = false;
        state.error = action.payload;
      })
      .addCase(fetchUserPurchasedVideos.pending, (state) => {
        state.loading.fetchUserPurchasedVideos = true;
        state.error = null;
      })
      .addCase(fetchUserPurchasedVideos.fulfilled, (state, action) => {
        state.loading.fetchUserPurchasedVideos = false;
        state.userPurchasedVideos = action.payload;
      })
      .addCase(fetchUserPurchasedVideos.rejected, (state, action) => {
        state.loading.fetchUserPurchasedVideos = false;
        state.error = action.payload;
      })
  },
});


export const { resetVideoState, setDownloadProgress, resetDownloadProgress } = videoSlice.actions;

export default videoSlice.reducer;
