// src/pages/DashboardPage.js

import React from 'react';
import styled from 'styled-components';
import Dashboard from '../components/Dashboard/Dashboard';
import Footer from '../components/common/Footer';
import ErrorBoundary from '../components/common/ErrorBoundary';
import { media } from '../utils/media'; // Import media utility

const DashboardPage = () => {
  return (
    <PageContainer>
      <ErrorBoundary>
        <Dashboard />
      </ErrorBoundary>
    </PageContainer>
  );
};

export default DashboardPage;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  padding: 30px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);

  ${media.tablet(`
    padding: 20px;
  `)}

  ${media.mobile(`
    padding: 15px;
  `)}
`;
