import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import NavBar from '../components/common/NavBar';
import Footer from '../components/common/Footer';

const PaymentCancelled = () => {
  const location = useLocation();
  const videoId = new URLSearchParams(location.search).get('id');
  const navigate = useNavigate();

  const handleRetry = () => {
    navigate(`/trim-video?id=${videoId}`);
  };

  const handleDashboardRedirect = () => {
    navigate('/dashboard');
  };

  return (
    <PageContainer>
      <NavBar />
      <Content>
        <Message>
          Your payment was canceled or failed. If you would like to try again, please click the button below.
        </Message>
        <RetryButton onClick={handleRetry}>Retry Payment</RetryButton>
        <SecondaryButton onClick={handleDashboardRedirect}>Go to Dashboard</SecondaryButton>
      </Content>
      <Footer />
    </PageContainer>
  );
};

export default PaymentCancelled;

// Styled Components

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f0f2f5;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
`;

const Message = styled.p`
  font-size: 18px;
  color: #333;
  margin-bottom: 20px;
`;

const RetryButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #c41e3a;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #a0001a;
  }
`;

const SecondaryButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #666;
  color: white;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #444;
  }
`;
