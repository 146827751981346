// src/components/Video/VideoListing.js

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVideos } from '../../store/videoSlice';
import VideoCard from './VideoCard';
import styled from 'styled-components';
import useFilteredVideos from './UseFilteredVideos'; // Import the custom hook
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import Pagination from './Pagination'; // Import the Pagination component

// Number of items per page
const ITEMS_PER_PAGE = 10;

// Utility function for converting time
const convertTimeToSeconds = (timeStr) => {
  if (!timeStr) return NaN;
  const [time, modifier] = timeStr.split(' ');
  let [hours, minutes] = time.split(':').map(Number);

  if (modifier === 'PM' && hours !== 12) {
    hours += 12;
  } else if (modifier === 'AM' && hours === 12) {
    hours = 0;
  }

  if (
    isNaN(hours) ||
    isNaN(minutes) ||
    hours < 0 ||
    hours > 23 ||
    minutes < 0 ||
    minutes > 59
  )
    return NaN;
  return hours * 3600 + minutes * 60;
};

const VideoListing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const videos = useSelector((state) => state.video.videos);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectedDate, setSelectedDate] = useState('today');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isNavigating, setIsNavigating] = useState(false);
  const [trimSegments, setTrimSegments] = useState([]);
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const filteredVideos = useFilteredVideos(videos, selectedDate, startTime, endTime);

  const totalPages = useMemo(() => Math.ceil(filteredVideos.length / ITEMS_PER_PAGE), [filteredVideos.length]);

  // Compute current page videos
  const currentVideos = useMemo(() => {
    const indexOfLastVideo = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstVideo = indexOfLastVideo - ITEMS_PER_PAGE;
    return filteredVideos.slice(indexOfFirstVideo, indexOfLastVideo);
  }, [filteredVideos, currentPage]);

  // Time options for select dropdown excluding 11:00 PM - 6:00 AM
  const timeOptions = useMemo(
    () =>
      Array.from({ length: 24 * 4 }, (_, i) => {
        const hour = Math.floor(i / 4) % 12 || 12;
        const minutes = ['00', '15', '30', '45'][i % 4];
        const period = i < 24 * 2 ? 'AM' : 'PM';
        const timeString = `${hour}:${minutes} ${period}`;

        const timeInSeconds = convertTimeToSeconds(timeString);
        const elevenPMInSeconds = convertTimeToSeconds('11:00 PM');
        const sixAMInSeconds = convertTimeToSeconds('6:00 AM');

        // Exclude times between 11:00 PM and 6:00 AM
        if (timeInSeconds >= elevenPMInSeconds || timeInSeconds < sixAMInSeconds) {
          return null; // Exclude this time
        }

        return timeString;
      }).filter(Boolean), // Remove null values
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        await dispatch(fetchVideos()).unwrap();
      } catch (error) {
        setError('Failed to load videos. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  // Handle date change
  const handleDateChange = useCallback((e) => setSelectedDate(e.target.value), []);

  // Handle start and end time change
  const handleTimeChange = useCallback(
    (setter, type) => (e) => {
      const value = e.target.value;
      if (!value) {
        // If user clears the selection
        setter('');
        setValidationError('');
        return;
      }

      const [time, modifier] = value.split(' ');
      const [hours, minutes] = time.split(':').map(Number);

      if (
        !isNaN(hours) &&
        !isNaN(minutes) &&
        hours >= 1 &&
        hours <= 12 &&
        minutes >= 0 &&
        minutes < 60 &&
        (modifier === 'AM' || modifier === 'PM')
      ) {
        setter(value);
        setValidationError('');
      } else {
        setValidationError('Invalid time format. Please use HH:MM AM/PM format.');
        setter('');
        return;
      }

      if (type === 'start' && endTime) {
        const startSeconds = convertTimeToSeconds(value);
        const endSeconds = convertTimeToSeconds(endTime);
        if (startSeconds >= endSeconds) {
          setValidationError('Start time must be before end time.');
          setter('');
        }
      }

      if (type === 'end' && startTime) {
        const startSeconds = convertTimeToSeconds(startTime);
        const endSeconds = convertTimeToSeconds(value);
        if (startSeconds >= endSeconds) {
          setValidationError('End time must be after start time.');
          setter('');
        }
      }
    },
    [startTime, endTime]
  );

  // Handle video selection
  const handleVideoSelection = useCallback((videoId) => {
    setSelectedVideos((prevSelected) => {
      if (prevSelected.includes(videoId)) {
        return prevSelected.filter((id) => id !== videoId);
      }
      return [...prevSelected, videoId];
    });
  }, []);

  // Proceed to confirmation page with selected data
  const handleProceedToConfirmation = useCallback(() => {
    setIsNavigating(true);
    const videoIds = selectedVideos;

    navigate('/confirmation', {
      state: {
        videoIds,
        thumbnails: filteredVideos
          .filter((video) => videoIds.includes(video.id))
          .map((video) => video.thumbnails?.[Object.keys(video.thumbnails)[0]] || '/fallback-thumbnail.png'),
        processing: true,
      },
    });
  }, [navigate, filteredVideos, selectedVideos]);

  // Clear filters and reset form
  const handleClearFilters = () => {
    setStartTime('');
    setEndTime('');
    setSelectedVideos([]);
    setTrimSegments([]); // Clear trim segments
    setValidationError('');
    setCurrentPage(1);
  };

  // Reset to first page whenever filters change
  useEffect(() => {
    setCurrentPage(1);
  }, [selectedDate, startTime, endTime]);

  // Handle page change
  const handlePageChange = useCallback(
    (page) => {
      if (page < 1 || page > totalPages) return;
      setCurrentPage(page);
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top on page change
    },
    [totalPages]
  );

  // Loading spinner
  if (isLoading) {
    return (
      <Container>
        <CircularProgress />
        <LoadingMessage>Loading videos...</LoadingMessage>
      </Container>
    );
  }

  // Error message
  if (error) {
    return (
      <Container>
        <ErrorMessage>{error}</ErrorMessage>
        <RetryButton onClick={() => dispatch(fetchVideos()).unwrap()}>Retry</RetryButton>
      </Container>
    );
  }

  return (
    <Container>
      {isNavigating && <LoadingMessage>Redirecting to confirmation...</LoadingMessage>}
      <FilterContainer>
        {/* Date Selector */}
        <SelectorWrapper>
          <Label htmlFor="date">Date:</Label>
          <Select id="date" value={selectedDate} onChange={handleDateChange} aria-label="Select Date">
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="last2days">Last 2 Days</option>
          </Select>
          <InlineHelpText>Select a date to filter videos.</InlineHelpText>
        </SelectorWrapper>

        {/* Start Time Selector */}
        <SelectorWrapper>
          <Label htmlFor="startTime">Start Time:</Label>
          <Select
            id="startTime"
            value={startTime}
            onChange={handleTimeChange(setStartTime, 'start')}
            aria-label="Select Start Time"
          >
            <option value="">Select Start Time</option>
            {timeOptions.map((time) => (
              <option key={time} value={time}>
                {time}
              </option>
            ))}
          </Select>
          <InlineHelpText>Choose a start time.</InlineHelpText>
        </SelectorWrapper>

        {/* End Time Selector */}
        <SelectorWrapper>
          <Label htmlFor="endTime">End Time:</Label>
          <Select
            id="endTime"
            value={endTime}
            onChange={handleTimeChange(setEndTime, 'end')}
            aria-label="Select End Time"
          >
            <option value="">Select End Time</option>
            {timeOptions.map((time) => (
              <option key={time} value={time}>
                {time}
              </option>
            ))}
          </Select>
          <InlineHelpText>Choose an end time.</InlineHelpText>
        </SelectorWrapper>

        {/* Clear Filters Button */}
        <ClearButton onClick={handleClearFilters}>Clear Filters</ClearButton>
      </FilterContainer>

      {validationError && <ValidationError>{validationError}</ValidationError>}

      {startTime && endTime && !validationError && filteredVideos.length > 0 && (
        <ConfirmSegmentsText>
          Confirm desired segments within chosen time range
        </ConfirmSegmentsText>
      )}

      <VideoListingContainer>
        {currentVideos.length > 0 ? (
          currentVideos.map((video) => (
            <VideoCard
              key={video.id}
              video={video}
              isSelected={selectedVideos.includes(video.id)}
              onSelect={() => handleVideoSelection(video.id)}
            />
          ))
        ) : (
          <NoVideosMessage>No videos available for the selected timeframe.</NoVideosMessage>
        )}
      </VideoListingContainer>

      {selectedVideos.length >= 1 && (
        <ButtonWrapper>
          <Button onClick={handleProceedToConfirmation}>Proceed to Confirmation</Button>
        </ButtonWrapper>
      )}

      {totalPages > 1 && (
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
      )}
    </Container>
  );
};

export default VideoListing;

// Styled Components

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing.lg};
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primaryBackground};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: ${({ theme }) => theme.spacing.md};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: ${({ theme }) => theme.spacing.sm};
  }
`;

const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  margin-bottom: ${({ theme }) => theme.spacing.lg};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }
`;

const SelectorWrapper = styled.div`
  flex: 1 1 200px;
  margin: ${({ theme }) => theme.spacing.sm};
  min-width: 200px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    min-width: 100%;
  }
`;

const Label = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.md};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  display: block;
  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: ${({ theme }) => theme.fonts.primary};
`;

const Select = styled.select`
  padding: ${({ theme }) => theme.spacing.sm};
  font-size: ${({ theme }) => theme.fontSizes.md};
  width: 100%;
  border-radius: ${({ theme }) => theme.spacing.xs};
  border: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.inputBackground};
  transition: ${({ theme }) => theme.transitions.input};
  font-family: ${({ theme }) => theme.fonts.secondary};

  &:focus {
    border-color: ${({ theme }) => theme.colors.secondary};
    outline: none;
    box-shadow: 0 0 5px ${({ theme }) => theme.colors.secondary}50;
  }
`;

const ClearButton = styled.button`
  background-color: ${({ theme }) => theme.colors.secondary};
  color: #ffffff;
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.lg};
  border: none;
  border-radius: ${({ theme }) => theme.spacing.xs};
  cursor: pointer;
  margin: ${({ theme }) => theme.spacing.sm};
  transition: ${({ theme }) => theme.transitions.button};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-family: ${({ theme }) => theme.fonts.primary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.accents[1]};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 5px ${({ theme }) => theme.colors.secondary}50;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

const VideoListingContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: ${({ theme }) => theme.spacing.md};
  width: 100%;
  max-width: 1200px;
  margin-bottom: ${({ theme }) => theme.spacing.lg};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    gap: ${({ theme }) => theme.spacing.sm};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.lg};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }
`;

const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.secondary};
  color: #ffffff;
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.lg};
  border: none;
  border-radius: ${({ theme }) => theme.spacing.xs};
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.md};
  transition: ${({ theme }) => theme.transitions.button};
  box-shadow: ${({ theme }) => theme.shadows.small};
  font-family: ${({ theme }) => theme.fonts.primary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.accents[1]};
    transform: translateY(-2px);
    box-shadow: ${({ theme }) => theme.shadows.medium};
  }

  &:active {
    transform: translateY(0);
    box-shadow: ${({ theme }) => theme.shadows.small};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 5px ${({ theme }) => theme.colors.secondary}50;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    padding: ${({ theme }) => theme.spacing.sm};
  }
`;

const NoVideosMessage = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.lg};
  color: ${({ theme }) => theme.colors.textSecondary};
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing.lg};
  font-family: ${({ theme }) => theme.fonts.secondary};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fontSizes.md};
    margin-top: ${({ theme }) => theme.spacing.md};
  }
`;

const LoadingMessage = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.lg};
  color: ${({ theme }) => theme.colors.accents[2]};
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing.lg};
  font-family: ${({ theme }) => theme.fonts.secondary};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fontSizes.md};
    margin-top: ${({ theme }) => theme.spacing.md};
  }
`;

const ErrorMessage = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.lg};
  color: ${({ theme }) => theme.colors.error};
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing.lg};
  font-family: ${({ theme }) => theme.fonts.secondary};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fontSizes.md};
    margin-top: ${({ theme }) => theme.spacing.md};
  }
`;

const RetryButton = styled.button`
  background-color: ${({ theme }) => theme.colors.secondary};
  color: #ffffff;
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  border: none;
  border-radius: ${({ theme }) => theme.spacing.xs};
  cursor: pointer;
  margin-top: ${({ theme }) => theme.spacing.md};
  transition: ${({ theme }) => theme.transitions.button};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-family: ${({ theme }) => theme.fonts.primary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.accents[1]};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 5px ${({ theme }) => theme.colors.secondary}50;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    padding: ${({ theme }) => theme.spacing.sm};
  }
`;

const ValidationError = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: ${({ theme }) => theme.colors.error};
  margin-top: ${({ theme }) => theme.spacing.sm};
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.secondary};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: ${({ theme }) => theme.spacing.sm};
  }
`;

const ConfirmSegmentsText = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: ${({ theme }) => theme.fontSizes.md};
  margin-bottom: ${({ theme }) => theme.spacing.md};
  text-align: center;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.secondary};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fontSizes.md};
    margin-bottom: ${({ theme }) => theme.spacing.sm};
  }
`;

const InlineHelpText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-top: ${({ theme }) => theme.spacing.xs};
  font-style: italic;
  font-family: ${({ theme }) => theme.fonts.secondary};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fontSizes.sm};
  }
`;