
import React from 'react';
import { FaWallet, FaMedal, FaShieldAlt, FaThumbsUp, FaTrophy, FaLock, FaShareAlt, FaClock, FaRedo } from 'react-icons/fa';
export const advantageData = [
    {
      icon: <FaWallet />,
      title: 'Competitive Advantage',
      description:
        'Enhance your game with instant access to high-definition footage. Whether you’re striving for precision or mastering technique, our platform helps you spot opportunities for improvement and develop strategies to outplay your competition.',
    },
    {
      icon: <FaRedo />,
      title: 'Relive the Moment',
      description:
        'Never miss a moment. Go back and relive those game-changing plays, clutch shots, and defining victories anytime, anywhere. Whether it’s to celebrate your wins or analyze your performance, our platform gives you seamless access to every highlight, so the memories stay fresh and your skills keep sharpening.',
    },
    {
      icon: <FaShareAlt />,
      title: 'Share',
      description:
        'Celebrate with your community. Share your standout moments with teammates, coaches, and friends in just a few clicks. Whether it’s bragging rights or building your portfolio, you can showcase your best plays across social media or keep them secure for your personal collection.',
    },
    {
      icon: <FaThumbsUp />,
      title: 'Easy to Use',
      description:
        'Accessing your footage is as easy as scanning our QR code after your game, picking the time and court that you played on, purchasing your video, and then accessing it on your Akture Dashboard.',
    },
  ];


  export const advantageDataVenues = [
    {
      icon: <FaWallet />,
      title: 'Novel Revenue Stream',
      description:
        'Using emerging technologies and proprietary software, every memory created on your courts/fields can now be purchased generating an entirely new source of income for your business. ',
    },
    {
      icon: <FaTrophy />,
      title: 'Competitive Advantage',
      description:
        'Once our system is installed, players will be incentivized to return to your location where they can purchase their precious memories as opposed to your competitors where those memories would be lost.',
    },
    {
      icon: <FaLock />,
      title: 'Security',
      description: 'Our camera systems can provide added security and replace your existing security at better costs.',
    },

    {
      icon: <FaThumbsUp />,
      title: 'Easy to Use',
      description:
        'Once our guest has finished their game, they are able to scan our QR code where they can purchase their videos and nearly instant access their footage to analyse and share on social media'
    },
  ];