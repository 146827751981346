// src/components/HeroSection.js

import React from 'react';
import styled from 'styled-components';
import { FaArrowDown } from 'react-icons/fa';
import HeroImage from '../assets/images/hero.jpg';

const HeroSection = ({ user, handleLogin }) => {
  return (
    <HeroJsxSection>
      <HeroContent>
        <ResponsiveH1>
          All Your Moments, <SpanText>Perfectly Preserved</SpanText>
        </ResponsiveH1>
        <ResponsiveP>Your best sport memories, at your fingertips.</ResponsiveP>
        {!user && (
          <JoinButton onClick={handleLogin} aria-label="Sign Up or Login">
            Sign Up / Login
          </JoinButton>
        )}
      </HeroContent>
      <ScrollDownLink href="#vision" aria-label="Scroll down to Vision section">
        <AngleDownIcon>
          <FaArrowDown />
        </AngleDownIcon>
      </ScrollDownLink>
    </HeroJsxSection>
  );
};

export default HeroSection;

// Styled Components

// Hero Section Container
const HeroJsxSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background: url(${HeroImage}) no-repeat center center;
  background-size: cover;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.6),
        rgba(0, 0, 0, 0.8)
      ),
      rgba(0, 0, 0, 0.4);
    z-index: 1;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0 20px;
    height: auto;
    min-height: 100vh;
  }
`;

// Hero Content
const HeroContent = styled.div`
  position: relative;
  z-index: 2;
  color: #fff;
  animation: fadeIn 1.5s ease-in-out;
  max-width: 800px;
  padding: 0 20px;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

// Responsive Heading
const ResponsiveH1 = styled.h1`
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 4rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 2.5rem;
  }
`;

// Highlighted Span Text
const SpanText = styled.span`
  color: ${(props) => props.theme.colors.secondary};
  font-weight: 500;
`;

// Responsive Paragraph
const ResponsiveP = styled.p`
  font-size: 1.75rem;
  margin-bottom: 30px;
  font-weight: 300;
  color: #fff;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 1.25rem;
  }
`;

// Join Button
const JoinButton = styled.button`
  font-family: ${(props) => props.theme.fonts.primary};
  background-color: ${(props) => props.theme.colors.secondary};
  color: #fff;
  border: none;
  text-transform: uppercase;
  padding: 15px 40px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  border-radius: 28px;
  font-size: 1.25rem;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.accents[1]};
    transform: translateY(-5px);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 111, 60, 0.5);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 12px 30px;
    font-size: 1rem;
  }
`;

// Scroll Down Link
const ScrollDownLink = styled.a`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  text-decoration: none;
  z-index: 2;

  &:hover {
    opacity: 0.8;
  }
`;

// Down Arrow Icon
const AngleDownIcon = styled.div`
  font-size: 2.5rem;
  color: #fff;
  cursor: pointer;
  animation: bounce 2s infinite;

  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
`;

