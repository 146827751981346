// src/components/VisionSection.js

import React from 'react';
import styled from 'styled-components';
import { FaEye } from 'react-icons/fa';
import { Section, HorizontalLine, IconWrapper, SectionTitle } from './common/Sections';

const VisionSection = () => {
  return (
    <Section id="vision">
      <ContentBox>
        <Header>
          <IconWrapper>
            <FaEye aria-hidden="true" />
          </IconWrapper>
          <SectionTitle>Our Vision</SectionTitle>
          <HorizontalLine />
        </Header>
        <ContentRow>
          <ContentWrapper>
            <VisionText>
              We envision a world where every athlete has access to their sports footage,
              allowing them to relive their best moments, share their highlights, and
              improve their game.
            </VisionText>
          </ContentWrapper>
          <VideoWrapper>
            <iframe
              src="https://www.youtube.com/embed/RXuJuz5fkow"
              title="Akture Sports Vision Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </VideoWrapper>
        </ContentRow>
      </ContentBox>
    </Section>
  );
};

export default VisionSection;

// Styled Components

const ContentBox = styled.div`
  max-width: 1200px;
  margin: 0 auto 4rem auto;
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.darkBackground};
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  box-shadow: ${({ theme }) => theme.shadows.medium};
  border: 1px solid ${({ theme }) => theme.colors.accents[2]};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 1.5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 1rem;
    margin-bottom: 2rem;
  }
`;

const Header = styled.div`
  text-align: center;
`;

const ContentRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.lg};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    margin-top: ${({ theme }) => theme.spacing.md};
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  margin-right: ${({ theme }) => theme.spacing.lg};
  text-align: left;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-right: 0;
    margin-bottom: ${({ theme }) => theme.spacing.md};
    text-align: center;
  }
`;

const VisionText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.lg};
  line-height: 1.75;
  color: ${({ theme }) => theme.colors.secondaryBackground};
  margin-top: ${({ theme }) => theme.spacing.sm};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fontSizes.sm};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.fontSizes.xs};
  }
`;

const VideoWrapper = styled.div`
  flex: 1;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  box-shadow: ${({ theme }) => theme.shadows.small};

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: ${({ theme }) => theme.sizes.borderRadius};
    transition: ${({ theme }) => theme.transitions.default};

    &:hover,
    &:focus {
      transform: scale(1.02);
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    margin-top: ${({ theme }) => theme.spacing.sm};
  }
`;
