// src/components/common/Layout.js

import React from 'react';
import NavBar from './NavBar';
import Footer from './Footer';
import styled from 'styled-components';

const Layout = ({ children }) => {
  return (
    <PageContainer>
      <NavBar />
      <MainContent>{children}</MainContent>
      <Footer />
    </PageContainer>
  );
};

export default Layout;

// Styled Components

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContent = styled.main`
  flex: 1;
  padding-top: 60px; /* Height of the NavBar to prevent content overlap */
`;
