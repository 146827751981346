import React from 'react';
import VideoListing from '../components/Video/VideoListing';
import NavBar from '../components/common/NavBar';
import Footer from '../components/common/Footer';
import styled from 'styled-components';

const VideoPageContainer = styled.div`
  background-color: #f0f2f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const MainContent = styled.main`
  flex-grow: 1;
  padding-top: 80px;
`;

const VideoPage = () => {
  return (
    <VideoPageContainer>
      <MainContent>
        <VideoListing />
      </MainContent>
      {/* <Footer /> */}
    </VideoPageContainer>
  );
}

export default VideoPage;
