// src/components/Video/VideoCard.js

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { FaDownload, FaPlay, FaSpinner } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { downloadVideo, fetchUserPurchasedVideos } from '../../store/videoSlice';
import api from '../../utils/api';
import VideoTrimmer from './VideoTrimmer';
import { Modal, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  Card,
    ButtonContainer,
    TrimButton,
    CheckboxContainer,
    TooltipWrapper,
    TooltipText,
    ThumbnailContainer,
    StyledVideo,
    PlayButton,
    PurchaseOverlay,
    DownloadIconContainer,
    SpinnerIcon,
    Content,
    TitleContainer,
    Title,
    EditButton,
    DeleteButton,
    Description,
    ProgressWrapper,
    ProgressBar,
    ProgressLabel,
    DownloadCompleteLabel,
    ErrorMessage,
    Message,
    StyledModalHeader,
    StyledModalBody,
    StyledModalFooter,
    StyledModal,
    StyledTrimModalHeader,
    StyledTrimModalBody,
    StyledTrimModalFooter,
    StyledFormControl,
    StyledSaveButton,
    StyledCancelButton,
    ErrorText,
    ModalOverlay,
    ModalContainer,
    ModalTitle,
    ModalBody,
    ModalActions,
    CancelButton,
    ConfirmButton,

} from './styles/VideoCardStyles';

// Utility functions for formatting dates and times
export const formatDate = (uploadDate) => {
  const date = new Date(uploadDate);
  if (isNaN(date.getTime())) return 'Invalid Date';

  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(date);
};

export const formatDate2 = (uploadDate) => {
  const date = new Date(uploadDate);
  if (isNaN(date.getTime())) return 'Invalid Date';

  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(date);
};

export const formatDateExtractTime = (dateString) => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return 'Invalid Date';
  return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
};

export const formatDuration = (durationInSeconds) => {
  const minutes = Math.floor(durationInSeconds / 60);
  return `${minutes} min${minutes > 1 ? 's' : ''}`;
};

const VideoCard = ({ video, isSelected, onSelect }) => {
  const dispatch = useDispatch();
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const user = useSelector((state) => state.auth.user);
  const [newTitle, setNewTitle] = useState(video.title || '');
  const [showTrimModal, setShowTrimModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Download progress and status from Redux store
  const downloadProgress = useSelector((state) => state.video.downloadProgress[video.id]);
  const downloadStatus = useSelector((state) => state.video.downloadStatus[video.id]);

  const isDownloadInProgress = downloadStatus === 'loading';
  const isDownloadSuccessful = downloadStatus === 'success';
  const isDownloadFailed = downloadStatus === 'failed';

  const getDisplayTitle = () => {
    if (video.title && !isDefaultTitle(video.title.trim())) {
      return video.title;
    } else {
      // Use your formatting methods to generate the default title
      if (video.duration > 900) {
        return `Court 1 - ${formatDate2(video.startTime)} Duration: (${formatDuration(video.duration)})`;
      } else {
        return `Court 1 - ${formatDate(video.startTime)} - ${formatDateExtractTime(video.endTime)} (${formatDuration(video.duration)})`;
      }
    }
  };
  

useEffect(() => {
  setNewTitle(video.title || '');
}, [video.title]);




  // Handle opening and closing the trim modal
  const handleTrimClick = () => {
    setShowTrimModal(true);
  };

  const handleTrimClose = () => {
    setShowTrimModal(false);
  };

  // Handle opening and closing the edit modal
  const handleEdit = () => {
    setShowEditModal(true);
  };

const handleShowDeleteModal = () => setShowDeleteModal(true);
const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleClose = () => {
    setShowEditModal(false);
    setError('');
  };

  const handleTitleChange = (e) => {
    setNewTitle(e.target.value);
  };

  // Handle saving the new title
  const handleSave = async () => {
    if (newTitle.trim() === '') {
      setError('Title cannot be empty.');
      return;
    }
    setLoading(true);
    try {
      await api.put(`/videos/${video.id}/edit-name?newTitle=${encodeURIComponent(newTitle)}`);
      video.title = newTitle;
      setNewTitle(newTitle);
      await dispatch(fetchUserPurchasedVideos(user.id)); // Dispatch the action to update the Redux store
      handleClose();
    } catch (error) {
      console.error('Error updating video title:', error);
      setError('Failed to update video title. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const isDefaultTitle = (title) => {
    const defaultTitlePattern = /^video_\d{8}_\d{6}\.mp4$/;
    return defaultTitlePattern.test(title);
  };
  
  

  // Handle video download
  const handleDownloadClick = useCallback(
    (e) => {
      e.stopPropagation(); // Prevent triggering other click events
      if (!video.isPurchased) {
        console.warn('You need to purchase this video to download it.');
        return;
      }
      if (!isDownloadInProgress) {
        dispatch(downloadVideo(video.id));
      }
    },
    [dispatch, video.id, video.isPurchased, isDownloadInProgress]
  );

  // Handle video playback
  const handlePlayButtonClick = useCallback(
    (e) => {
      e.stopPropagation(); // Prevent triggering parent click events

      if (video.isPurchased) {
        const videoElement = videoRef.current;

        if (videoElement) {
          if (videoElement.paused) {
            videoElement
              .play()
              .then(() => {
                console.log('Video started playing.');
              })
              .catch((error) => {
                console.error('Error trying to play the video:', error);
              });
          } else {
            videoElement.pause();
          }
        } else {
          console.error('Video ref is not available');
        }
      } else {
        console.warn('Please purchase this video to play.');
      }
    },
    [video.isPurchased]
  );

  // Update isPlaying state based on video events
  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;

    const handlePlay = () => {
      console.log('Video is playing.');
      setIsPlaying(true);
    };
    const handlePause = () => {
      console.log('Video is paused.');
      setIsPlaying(false);
    };
    const handleEnded = () => {
      console.log('Video has ended.');
      setIsPlaying(false);
    };

    videoElement.addEventListener('play', handlePlay);
    videoElement.addEventListener('pause', handlePause);
    videoElement.addEventListener('ended', handleEnded);

    return () => {
      videoElement.removeEventListener('play', handlePlay);
      videoElement.removeEventListener('pause', handlePause);
      videoElement.removeEventListener('ended', handleEnded);
    };
  }, []);

  const handleDelete = async () => {



    try {
      await api.delete(`/videos/delete/${video.id}`);
      toast.success('Video deleted successfully.');
      await dispatch(fetchUserPurchasedVideos(user.id)); // Dispatch the action to update the Redux store
      handleCloseDeleteModal();

    } catch (error) {
      console.error('Error deleting video:', error);
      toast.error('Failed to delete video. Please try again.');
  }
};

  return (
    <Card>
      <CheckboxContainer>
        <TooltipWrapper>
          <input
            type="checkbox"
            checked={isSelected}
            onChange={onSelect}
            aria-label={`Select video ${video.id}`}
          />
          <TooltipText>Select this video</TooltipText>
        </TooltipWrapper>
      </CheckboxContainer>

      <ThumbnailContainer>
        <StyledVideo
          ref={videoRef}
          muted
          preload="metadata"
          controls
          playsInline
          poster={video.thumbnails?.[Object.keys(video.thumbnails)[0]] || '/fallback-thumbnail.png'}
        >
          <source src={video.preSignedS3Url} type="video/mp4" />
          Your browser does not support the video tag.
        </StyledVideo>
        {!video.isPurchased && <PurchaseOverlay>Purchase Required</PurchaseOverlay>}

        {video.isPurchased && !isPlaying && (
          <PlayButton onClick={handlePlayButtonClick} aria-label="Play video">
            <FaPlay size={20} color="#fff" />
          </PlayButton>
        )}

        {video.isPurchased && (
          <DownloadIconContainer
            onClick={handleDownloadClick}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleDownloadClick(e);
              }
            }}
            aria-label={`Download video ${video.id}`}
            isDownloadStarted={isDownloadInProgress}
            aria-disabled={isDownloadInProgress}
          >
            {isDownloadInProgress ? <SpinnerIcon aria-label="Downloading" /> : <FaDownload size={20} color="#FFFFFF" />}
          </DownloadIconContainer>
        )}
      </ThumbnailContainer>

      <Content>
        <TitleContainer>
        <Title isPurchased={video.isPurchased}>{getDisplayTitle()}</Title>
          {video.isPurchased && <EditButton onClick={handleEdit}>Edit</EditButton>}
          {video.isPurchased && <TrimButton onClick={handleTrimClick}>Trim</TrimButton>}
          {video.isPurchased && <DeleteButton onClick={handleShowDeleteModal}>Delete</DeleteButton>}
        </TitleContainer>

        {/* Trim Modal */}
        <Modal
          show={showTrimModal}
          onHide={handleTrimClose}
          centered
          backdrop="static"
          keyboard={false}
          size="md"
        >
          <StyledTrimModalHeader closeButton>
            <Modal.Title>Trim Video</Modal.Title>
          </StyledTrimModalHeader>
          <StyledTrimModalBody>
            <div className="video-trimmer-container">
              <VideoTrimmer video={video} onClose={handleTrimClose} />
            </div>
          </StyledTrimModalBody>
        </Modal>

        {/* Edit Modal */}
        <Modal
          show={showEditModal}
          onHide={handleClose}
          centered
          backdrop="static"
          keyboard={false}
        >
          <StyledModalHeader closeButton>
            <Modal.Title>Edit Video Title</Modal.Title>
          </StyledModalHeader>
          <StyledModalBody>
            <Form>
              <Form.Group controlId="formVideoTitle">
                <Form.Label>Video Title</Form.Label>
                <StyledFormControl
                  type="text"
                  value={newTitle}
                  onChange={handleTitleChange}
                  placeholder="Enter new video title"
                  autoFocus
                />
              </Form.Group>
              {error && <ErrorText>{error}</ErrorText>}
            </Form>
          </StyledModalBody>
          <StyledModalFooter>
            <StyledCancelButton variant="secondary" onClick={handleClose}>
              Cancel
            </StyledCancelButton>
            <StyledSaveButton variant="primary" onClick={handleSave} disabled={loading}>
              {loading ? 'Saving...' : 'Save'}
            </StyledSaveButton>
          </StyledModalFooter>
        </Modal>

        {showDeleteModal && (
  <ModalOverlay>
    <ModalContainer>
      <ModalTitle>Confirm Deletion</ModalTitle>
      <ModalBody>
        Are you sure you want to delete this video? This action cannot be undone.
      </ModalBody>
      <ModalActions>
        <CancelButton onClick={handleCloseDeleteModal}>Cancel</CancelButton>
        <ConfirmButton onClick={handleDelete}>Delete</ConfirmButton>
      </ModalActions>
    </ModalContainer>
  </ModalOverlay>
)}

        <Description>{video.description}</Description>

        {isDownloadInProgress && (
          <ProgressWrapper>
            <ProgressBar style={{ width: `${downloadProgress}%` }} />
            <ProgressLabel>{`${downloadProgress}%`}</ProgressLabel>
          </ProgressWrapper>
        )}

        {isDownloadSuccessful && <DownloadCompleteLabel>Download Complete</DownloadCompleteLabel>}
        {isDownloadFailed && <ErrorMessage>Download failed. Please try again.</ErrorMessage>}
        {!video.isPurchased && <Message>Please purchase this video to access more features.</Message>}
      </Content>
    </Card>
  );
};

VideoCard.propTypes = {
  video: PropTypes.shape({
    id: PropTypes.number.isRequired,
    preSignedS3Url: PropTypes.string.isRequired,
    uploadDate: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    description: PropTypes.string,
    thumbnails: PropTypes.objectOf(PropTypes.string),
    isPurchased: PropTypes.bool,
    title: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
  }).isRequired,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default React.memo(VideoCard);