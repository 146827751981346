// src/components/common/theme.js

export const theme = {
  colors: {
    primaryBackground: '#f9f9f9', // Light grey
    secondaryBackground: '#ffffff', // White
    primary: '#010101', // Black
    secondary: '#BD2026', // Red
    accents: ['#00a693', '#ff6f3c', '#5a7684'], // Teal, orange, and grey
    textPrimary: '#333333', // Dark text used for headers
    textSecondary: '#666666', // Medium text used for subheaders
    border: '#010101', // Border color matching primary
    inputBackground: '#f9f9f9', // Input background
    inputFocusBackground: '#fdf2f4', // Input focus background
    error: '#e63946', // Error color
    disabledBackground: '#e0e0e0', // Disabled input/button background
    darkBackground: '#111', // Dark background
    title: '#333333', // Title color

  },
  breakpoints: {
    mobile: '480px',
    tablet: '768px',
    desktop: '1024px',
    largeDesktop: '1200px',
  },
  fontSizes: {
    sm: '0.875rem', // 14px
    md: '1rem',     // 16px
    lg: '1.25rem',  // 20px
    '2xl': '1.5rem', // 24px
    // Add more font sizes as needed
  },
  sizes: {
    logo: {
      small: '40px',   // For mobile
      medium: '60px',  // For tablet
      large: '80px',   // For desktop and above
    },
    // Add more size definitions as needed
  },
  fonts: {
    primary: "'Epilogue', Alumni Sans, sans-serif",
    secondary: "'Alumni Sans', sans-serif",
    // Add more fonts as needed
  },
  spacing: {
    xs: '0.25rem',  // 4px
    sm: '0.5rem',   // 8px
    md: '1rem',     // 16px
    lg: '1.5rem',   // 24px
    xl: '2rem',     // 32px
  },
  shadows: {
    small: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)',
    medium: '0 0.5rem 1rem rgba(0, 0, 0, 0.1)',
    large: '0 1rem 2rem rgba(0, 0, 0, 0.15)',
  },
  transitions: {
    link: 'color 0.3s ease',
    button: 'background-color 0.3s ease, color 0.3s ease',
    input: 'border-color 0.3s ease, box-shadow 0.3s ease',
    card: 'transform 0.3s ease, box-shadow 0.3s ease',
  },
};
