import React from 'react';
import styled from 'styled-components';

const Header = ({ logoSrc, altText, title, subtitle}) => {
    return (
        <HeaderContainer>
            {/* <Logo src={logoSrc} alt={altText} /> */}
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
        </HeaderContainer>
    );

}

export default Header;




const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    text-align: center;
`;

const Logo = styled.img`
    width: 120px;
    height: auto;
    margin-bottom: 10px;

    @media (max-width: 768px) {
        width: 100px;
    }
`;

const Title = styled.h1`
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 5px;

    @media (max-width: 768px) {
        font-size: 22px;
    }
`;

const Subtitle = styled.p`
    font-size: 18px;
    margin-bottom: 30px;

    @media (max-width: 768px) {
        font-size: 16px;
    }
`;